import { Box, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import Colors from "../../enums/colors";
import { useHistory } from "react-router-dom";
import { SnackbarContext } from "../../context/snackbar";
import { Link } from "react-router-dom";
import BackButton from "../../components/BackButton";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";
import { GlobalLoaderContext } from "../../context/global-loader";
import BaseComponentLogin from "./baseComponentLogin";
import { isValidEmail } from ".";
import ReCAPTCHA from "react-google-recaptcha";
import { TranslationsContext } from "../../context/translations";
import { AuthContext } from "../../context/auth";
import { useParams } from "react-router";
import { getCookie, setCookie } from "../../App";

const ResendEmail = () => {
  const history = useHistory();
  const { profile, app } = useParams();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);

  const { translations } = useContext(TranslationsContext);

  const { profileNameLogin } = useContext(AuthContext);

  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName
    ? `${profileName} - Resend Email`
    : "Resend Email";

  useEffect(() => {
    setCookie(process.env.REACT_APP_MMAZ_APPLICATION_COOKIE, app, 365);
    setCookie(process.env.REACT_APP_MMAZ_PROFILE_COOKIE, profile, 365);
    setCookie(process.env.REACT_APP_MCZ_PROFILE_COOKIE, profile, 365);
  }, []);

  const [recaptchaApproved, setRecaptchaApproved] = useState(false);
  function onChangeRecaptcha(value) {
    if (value) {
      setRecaptchaApproved(true);
    } else {
      setRecaptchaApproved(false);
    }
  }

  const buttonSX = {
    backgroundColor: "#3399FE",
    "&:hover": {
      backgroundColor: "#004c97",
    },
  };
  const handleClick = async (event) => {
    startGlobalLoader();
    const payload = {
      email: email,
      application: app,
      profile: profile,
    };
    try {
      const response = await API.post(
        ApiUri.AUTH + ApiUri.ACTIVATION + ApiUri.RESEND,
        payload
      );
      initSnackbarSuccess(response?.data?.data);
      history.push("/");
    } catch (error: any) {
      initSnackbarError(error?.response?.data?.message);
    } finally {
      stopGlobalLoader();
    }
  };

  const handleBlur = () => {
    setEmailError(!isValidEmail(email));
  };

  const emailIsValid = isValidEmail(email);

  const handleChangeEmail = (event) => {
    const input = event.target.value.trim();
    setEmail(input);
    if (emailError) {
      setEmailError(!isValidEmail(input));
    }
  };

  return (
    <BaseComponentLogin backButton>
      <Stack alignItems="center" width={"100%"} spacing={2}>
        <h2>
          {translations["heading.activation_email"] ?? "Activation Email"}
        </h2>

        <TextField
          fullWidth
          size="small"
          label={translations["label.your_email"] ?? "Your Email"}
          value={email}
          onChange={handleChangeEmail}
          onBlur={handleBlur}
          error={emailError}
          inputProps={{ autoCapitalize: "none" }}
          // helperText={emailError ? "Invalid email" : ""}
        />

        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITEKEY_RECAPTCHA}
          onChange={onChangeRecaptcha}
        />
        <ActionButtonSmall
          sx={buttonSX}
          onClick={handleClick}
          disabled={email.length === 0 || !emailIsValid || !recaptchaApproved}
          fullWidth
        >
          {translations["button.resend_email"] ?? "Resend Email"}
        </ActionButtonSmall>
      </Stack>
    </BaseComponentLogin>
  );
};

export default ResendEmail;
