import React, { createContext, useState } from "react";
import "node-snackbar/dist/snackbar.css";
import { Country } from "../models/country/country";

export interface ICountriesContext {
  countries: Country[];
  setCountries: React.Dispatch<React.SetStateAction<Country[]>>;
}

export const CountriesContext = createContext({} as ICountriesContext);

const CountriesContextProvider = ({ children }: any) => {
  const [countries, setCountries] = useState<Country[]>([]);

  return (
    <CountriesContext.Provider
      value={{
        countries,
        setCountries,
      }}
    >
      {children}
    </CountriesContext.Provider>
  );
};

export default CountriesContextProvider;
