import React, { createContext, useState } from "react";
import "node-snackbar/dist/snackbar.css";
import { Translations } from "../models/translations/translations";
import { emptyTranslations } from "../models/translations/emptyTranslations";

export interface ITranslationsContext {
  translations: Translations;
  setTranslations: React.Dispatch<React.SetStateAction<Translations>>;
  hasFetchedTranslations: boolean;
  setHasFetchedTranslations: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TranslationsContext = createContext({} as ITranslationsContext);

const TranslationsContextProvider = ({ children }: any) => {
  const [translations, setTranslations] =
    useState<Translations>(emptyTranslations);

  const [hasFetchedTranslations, setHasFetchedTranslations] = useState(false);

  return (
    <TranslationsContext.Provider
      value={{
        translations,
        setTranslations,
        hasFetchedTranslations,
        setHasFetchedTranslations,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};

export default TranslationsContextProvider;
