import React, { useContext, useEffect } from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { GlobalLoaderContext } from "../../context/global-loader";
import { Chip, Grid, Paper, Stack, TextField } from "@mui/material";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import { SnackbarContext } from "../../context/snackbar";
import { TranslationsContext } from "../../context/translations";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";
import { AuthContext } from "../../context/auth";
import { getCookie } from "../../App";

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MaintenanceMode() {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { translations } = useContext(TranslationsContext);
  const { jwt } = useContext(AuthContext);

  const [password, setPassword] = React.useState("");
  const [status, setStatus] = React.useState(null);

  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName
    ? `${profileName} - Maintenance Mode`
    : "Maintenance Mode";

  useEffect(() => {
    if (status === null) {
      startGlobalLoader();
      API.get(ApiUri.ADMIN + ApiUri.MAINTENANCE + ApiUri.STATUS, config).then(
        (response) => {
          stopGlobalLoader();
          setStatus(response.data.data.status);
        },
        (error) => {
          stopGlobalLoader();
        }
      );
    }
  }, []);

  const handleEdit = (event) => {
    setPassword(event.target.value);
  };

  const handleClick = (event) => {
    startGlobalLoader();
    const payload = {
      status: !status,
      password: password,
    };
    API.put(
      ApiUri.ADMIN + ApiUri.MAINTENANCE + ApiUri.UPDATE,
      payload,
      config
    ).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess(
          response.data.data.status
            ? translations["help.access_disabled"] ??
                "Access DISABLED for all users"
            : translations["help.access_enabled"] ??
                "Access ENABLED for all users"
        );
        setStatus(response.data.data.status);
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  return (
    <React.Fragment>
      {status !== null && (
        <Grid container>
          <Grid item xs={12} md={8}>
            <Paper elevation={3}>
              <Stack p={2}>
                <Chip
                  label={
                    status
                      ? translations["help.access_disabled"] ??
                        "Access DISABLED for all users"
                      : translations["help.access_enabled"] ??
                        "Access ENABLED for all users"
                  }
                  color={status ? "error" : "success"}
                />
                <p>
                  {translations["help.maintenance_mode"] ??
                    "This option will disable/enable access to the site for all users with the exception of MyClientZone (service) administrators. An email will also be sent notifying the tech support of the activation."}
                </p>
                <TextField
                  type="password"
                  sx={{ marginBottom: "25px" }}
                  fullWidth
                  label={translations["label.password"] ?? "Password"}
                  name="password"
                  value={password}
                  onChange={handleEdit}
                ></TextField>
                <ActionButtonSmall
                  onClick={handleClick}
                  disabled={password.length === 0}
                >
                  {status
                    ? translations["button.enable_access"] ?? "Enable Access"
                    : translations["button.disable_access"] ?? "Disable Access"}
                </ActionButtonSmall>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
