import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState } from "react";

const BackButton = () => {
  const [backButtonFocused, setBackButtonFocused] = useState(false);
  const handleFocus = () => {
    setBackButtonFocused(true);
  };

  const handleBlur = () => {
    setBackButtonFocused(false);
  };
  return (
    <Link to="/">
      <IconButton
        onFocus={handleFocus}
        onBlur={handleBlur}
        color={backButtonFocused ? "primary" : "default"}
        sx={{
          backgroundColor: "transparent",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
        }}
      >
        <ArrowBack sx={{ width: "45px", height: "45px", color: "#000000" }} />
      </IconButton>
    </Link>
  );
};

export default BackButton;
