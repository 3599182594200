import React, { useEffect } from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import Colors from "../../enums/colors";
import { getCookie } from "../../App";

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HelpZone() {
  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName ? `${profileName} - Help` : "Help";

  // todo: CONNECT TO BACKEND

  return (
    <React.Fragment>
      <Paper elevation={3} sx={{ marginBottom: "20px" }}>
        <Stack p={2}>
          <h1>Help</h1>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<AddIcon />}
              sx={{ backgroundColor: Colors.lightGrey }}
            >
              <Typography>Dashboard</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: Colors.lighterGrey }}>
              <Typography>Coming Soon...</Typography>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Paper>
    </React.Fragment>
  );
}
