import {
  Grid,
  Paper,
  Box,
  Divider,
  TextField,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import Colors from "../../../enums/colors";
import reportEncodings from "../../../context/report-encodings";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import SmallLoader from "../../../components/small-loader";
import { TranslationsContext } from "../../../context/translations";

// move the logic to get the status inside of this component
const TwoStepVerification = ({ config }) => {
  const [tfa, setTfa] = useState<any>(null);
  const [code, setCode] = useState("");

  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);

  const { translations } = useContext(TranslationsContext);

  const fetch2faStatus = async () => {
    const response = await API.get(
      // remove this hardcoded string if the endpoint url changes
      "/api/v1" + ApiUri.USER + ApiUri.TFA + ApiUri.STATUS,
      config
    );
    setTfa(response?.data?.data);
  };

  useEffect(() => {
    const checkData = async () => {
      startGlobalLoader();
      await fetch2faStatus();
      stopGlobalLoader();
    };
    checkData();
  }, []);

  const handleSave = async () => {
    startGlobalLoader();
    const payload = { code: code };
    // remove this hardcoded string if the endpoint url changes
    API.post("/api/v1" + ApiUri.USER + ApiUri.TFA, payload, config).then(
      (response) => {
        fetch2faStatus();
        setCode("");
        initSnackbarSuccess(
          translations["flash_message.2fa_updated"] ??
            "2FA updated successfully."
        );
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error?.response?.data?.message);
        stopGlobalLoader();
      }
    );
  };

  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ height: "100%" }}>
        <Box paddingX={2} paddingBottom={2} height={"100%"}>
          <Grid container height={"100%"}>
            <Grid item xs={12} md={12} paddingBottom={3}>
              <h1>{translations["heading.2fa"] ?? "2-Step Verification"}</h1>
              <Divider />
            </Grid>
            {tfa ? (
              tfa?.status ? (
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography>
                      {translations["2fa_content.activated"] ??
                        "2-Step Verification is activated."}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography fontSize="12px" color={Colors.grey}>
                      {translations["2fa_content.enter_code_to_disable"] ??
                        "To disable 2-Step Verification, please enter the code from your Google Authenticator app"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      // label={translations.code ?? "Code"}
                      value={code}
                      onChange={(event) => setCode(event.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ActionButtonSmall
                      name="save"
                      sx={{ backgroundColor: "#667080" }}
                      onClick={handleSave}
                      disabled={code.length === 0}
                    >
                      {translations["button.deactivate"] ?? "Deactivate"}
                    </ActionButtonSmall>
                  </Grid>
                </Grid>
              ) : (
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography fontSize="12px" color={Colors.grey}>
                      {"* " + translations["2fa_content.install_app"] ??
                        "To activate 2-Step Verification, please make sure you have Google Authenticator App installed on your phone"}
                    </Typography>
                    <Typography fontSize="12px" color={Colors.grey}>
                      {"* " + translations["2fa_content.open_app"] ??
                        "Open the Google Authenticator and click + to add new 2-Step Verification account"}
                    </Typography>
                    <Typography fontSize="12px" color={Colors.grey}>
                      {"* " + translations["2fa_content.scan_code"] ??
                        "Select QR code scanning and use your phone to scan code below:"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <img
                      style={{ height: "200px", width: "200px" }}
                      src={tfa?.qr_code}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography fontSize="12px" color={Colors.grey}>
                      {"* " + translations["2fa_content.add_account"] ??
                        "After you scan the QR code with your Google Authenticator app, you will successfully add new account"}
                    </Typography>
                    <Typography fontSize="12px" color={Colors.grey}>
                      {"* " + translations["2fa_content.enter_code"] ??
                        "Then, use the generated code from the Google Authenticator app and enter it here:"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      // label="Code"
                      value={code}
                      onChange={(event) => setCode(event.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography fontSize="12px" color={Colors.grey}>
                      {"* " + translations["2fa_content.confirm_code"] ??
                        'Click "Activate 2-Step Verification" to confirm the code'}
                    </Typography>
                    <Typography fontSize="12px" color={Colors.grey}>
                      {"* " + translations["2fa_content.additional_security"] ??
                        "2-Step Verification will be used as an additional security step on payment gateway settings and profile users invitation page"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <ActionButtonSmall
                      name="save"
                      sx={{ backgroundColor: "#667080" }}
                      onClick={handleSave}
                      disabled={code.length === 0}
                    >
                      {translations["button.activate"] ?? "Activate"}
                    </ActionButtonSmall>
                  </Grid>
                </Grid>
              )
            ) : (
              <SmallLoader />
            )}
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default TwoStepVerification;
