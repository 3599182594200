import React, { useContext } from "react";
import { Fab, Link } from "@mui/material";
import ActionButtonSmall from "../layout/buttons/action-button-small";
import { ConfirmationDialogContext } from "../../context/confirmation-dialog-context";

interface IConfirmationDialogActivator {
  onClick: any;
  type?: string;
  actionName: string;
  title?: string;
  content?: string;
  color?: string;
  icon?: any;
  props?: any;
  style?: any;
}

export default function ConfirmationDialogActivator({
  onClick,
  type = "button",
  actionName,
  title,
  content,
  color = "inherit",
  icon,
  style,
}: IConfirmationDialogActivator) {
  const { showConfirmation, setDialogTitle, setDialogContent } = useContext(ConfirmationDialogContext);

  const handleOnClick = async () => {
    if (title) {
      setDialogTitle(title);
    }

    if (content) {
      setDialogContent(content);
    }

    const result = await showConfirmation();
    result && onClick();
  };

  switch (type) {
    case "fab":
      return (
        <Fab
          style={style}
          size="small"
          variant={icon ? "circular" : "extended"}
          color={color as any}
          onClick={handleOnClick}
        >
          {icon ? icon : actionName}
        </Fab>
      );
    case "link":
      return (
        <Link style={{ textDecoration: "none", color: "inherit" }} onClick={handleOnClick}>
          {actionName}
        </Link>
      );
    default:
      return (
        <ActionButtonSmall style={style} onClick={handleOnClick}>
          {actionName}
        </ActionButtonSmall>
      );
  }
}
