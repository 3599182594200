import React, { useContext } from "react";
import { AuthContext } from "./context/auth";
import NavigationContextProvider from "./context/navigation";
import Breadcrumbs from "./layout/breadcrumbs";
import { Route } from "react-router-dom";

function PrivateRoute({ component: Component, ...rest }) {
  const { user } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props: any) =>
        user ? (
          <React.Fragment>
            <NavigationContextProvider>
              {/* <Breadcrumbs /> */}
            </NavigationContextProvider>
            <Component {...props} style={{ width: "100%", height: "100%" }} />
          </React.Fragment>
        ) : (
          window.location.replace(process.env.REACT_APP_MMAZ_URL!)
        )
      }
    />
  );
}

export default PrivateRoute;
