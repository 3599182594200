import React, { useContext, useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { GlobalLoaderContext } from "../../context/global-loader";
import {
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import Colors from "../../enums/colors";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import PaginationActions from "../../components/utilities/pagination-actions";
import { SnackbarContext } from "../../context/snackbar";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";
import { AuthContext, getJwtFromCookies } from "../../context/auth";
import { TranslationsContext } from "../../context/translations";
import { getCookie } from "../../App";

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BlockedIPs() {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { jwt } = useContext(AuthContext);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [blockedIPs, setBlockedIPs] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { initSnackbarSuccess, initSnackbarError, initSnackbarGeneric } =
    useContext(SnackbarContext);

  const { translations } = useContext(TranslationsContext);
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const fetchBlockedIps = () => {
    startGlobalLoader();
    API.get(ApiUri.ADMIN + ApiUri.BLOCKED_IPS, {
      params: {
        limit: rowsPerPage,
        page: page,
        search_term: searchQuery,
      },
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then(
      (response) => {
        setBlockedIPs(response.data.data);
        stopGlobalLoader();
      },
      (err) => {
        initSnackbarError(err);
        stopGlobalLoader();
      }
    );
  };

  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName ? `${profileName} - Blocked IPs` : "Blocked IPs";

  useEffect(() => {
    fetchBlockedIps();
  }, [rowsPerPage, page]);

  const handleUnblock = (ip) => {
    startGlobalLoader();
    API.delete(
      ApiUri.ADMIN + ApiUri.BLOCKED_IPS + "/" + ip + ApiUri.UNBLOCK,
      config
    ).then(
      (response) => {
        initSnackbarSuccess(response?.data?.data);
        fetchBlockedIps();
      },
      (err) => {
        initSnackbarError(err);
        stopGlobalLoader();
      }
    );
  };

  const [newIP, setNewIP] = useState("");

  const handleEdit = (event) => {
    const newValue = event.target.value;
    setNewIP(event.target.value.trim());
  };

  const handleBlock = () => {
    startGlobalLoader();
    const payload = { ip: newIP };
    API.post(
      ApiUri.ADMIN + ApiUri.BLOCKED_IPS + ApiUri.BLOCK,
      payload,
      config
    ).then(
      (response) => {
        initSnackbarSuccess(response?.data?.data);
        setNewIP("");
        fetchBlockedIps();
      },
      (err) => {
        initSnackbarError(err);
        stopGlobalLoader();
      }
    );
  };

  const handleEditQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  const rowSX = {
    "&:hover": {
      background: Colors.lighterGrey,
    },
  };

  return (
    <React.Fragment>
      <Stack alignItems="flex-end" spacing={2}>
        <Paper sx={{ alignSelf: "flex-start" }}>
          <Stack p={2} spacing={2}>
            <Typography variant="h5">
              {translations["heading.block_ip"] ?? "Block Another IP Address"}
            </Typography>
            <TextField
              label={translations["label.ip_address"] ?? "IP Address"}
              value={newIP}
              onChange={handleEdit}
            />
            <ActionButtonSmall
              disabled={newIP.length === 0}
              onClick={handleBlock}
            >
              {translations["button.block_ip"] ?? "Block"}
            </ActionButtonSmall>
          </Stack>
        </Paper>
        <TextField
          value={searchQuery}
          onChange={handleEditQuery}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ backgroundColor: Colors.lightGrey }}
        />
        <Paper sx={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: Colors.lightGrey }}>
                <TableRow>
                  <TableCell>
                    {translations["label.ip_address"] ?? "IP Address"}
                  </TableCell>
                  <TableCell>
                    {translations["table_field.blocked_by"] ?? "Blocked By"}
                  </TableCell>
                  <TableCell>
                    {translations["table_field.blocked_at"] ?? "Blocked At"}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {blockedIPs.map((row) => {
                  const blockedAt = new Date(row.blocked_at);
                  let day = blockedAt.getDate();
                  let month = blockedAt.getMonth() + 1;
                  const year = blockedAt.getFullYear();
                  const blockedAtParsed = `${day}/${month}/${year}`;
                  return (
                    <TableRow key={row.id} sx={rowSX}>
                      <TableCell component="th" scope="row">
                        {row.ip}
                      </TableCell>
                      <TableCell>
                        {row.blocked_by.user_profile.first_name +
                          " " +
                          row.blocked_by.user_profile.last_name}
                      </TableCell>
                      <TableCell>{blockedAtParsed}</TableCell>
                      <TableCell>
                        <ActionButtonSmall
                          value={row.id}
                          onClick={() => handleUnblock(row.id)}
                        >
                          {translations["button.unblock"] ?? "Unblock"}
                        </ActionButtonSmall>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 150, 200]}
            component="div"
            count={blockedIPs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </Paper>
      </Stack>
    </React.Fragment>
  );
}
