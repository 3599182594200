import React, { createContext, useState } from "react";
import "node-snackbar/dist/snackbar.css";
import { Currency } from "../models/currency/currency";

export interface ICurrenciesContext {
  currencies: Currency[];
  setCurrencies: React.Dispatch<React.SetStateAction<Currency[]>>;
}

export const CurrenciesContext = createContext({} as ICurrenciesContext);

const CurrenciesContextProvider = ({ children }: any) => {
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  return (
    <CurrenciesContext.Provider
      value={{
        currencies: currencies,
        setCurrencies: setCurrencies,
      }}
    >
      {children}
    </CurrenciesContext.Provider>
  );
};

export default CurrenciesContextProvider;
