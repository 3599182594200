export default class Colors {
  static primary = "#252B36";
  static secondary = "#353535";
  static accent = "#889263";
  static white = "#fff";
  static black = "#000";
  static success = "#f85168";
  static error = "#870019";
  static active = "#01579b";
  static background = "##fafafa";
  static grey = "#bdbdbd";
  static lightGrey = "#F2F2F2";
  static lighterGrey = "#F8F8F8";
  static darkerPrimary = "#1F242F"
  static blue = "#3399FE"
};
