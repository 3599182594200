import React, { createContext, useState } from "react";
import "node-snackbar/dist/snackbar.css";

export interface IInformationContext {
  information: any;
  setInformation: any;
}

export const InformationContext = createContext({} as IInformationContext);

const InformationContextProvider = ({ children }: any) => {
  const [information, setInformation] = useState(null);

  return (
    <InformationContext.Provider
      value={{
        information,
        setInformation,
      }}
    >
      {children}
    </InformationContext.Provider>
  );
};

export default InformationContextProvider;
