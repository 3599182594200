import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import WebFont from 'webfontloader';
import AppProvider from './AppProvider';
import {ThemeProvider} from '@mui/material/styles'
import theme from './theme'

ReactDOM.render(
  <ThemeProvider theme={theme}>
  <AppProvider>
    <App />
  </AppProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

WebFont.load({
  google: {
    families: ['Poppins:100,200,300,400,500,600,700,800,900', 'sans-serif']
  }
});
