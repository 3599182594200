import React, { createContext, useState } from "react";
import "node-snackbar/dist/snackbar.css";

export interface IReportEncodingsContext {
  reportEncodings: any;
  setReportEncodings: React.Dispatch<React.SetStateAction<any>>;
}

export const ReportEncodingsContext = createContext(
  {} as IReportEncodingsContext
);

const ReportEncodingsContextProvider = ({ children }: any) => {
  const [reportEncodings, setReportEncodings] = useState<any>([]);

  return (
    <ReportEncodingsContext.Provider
      value={{
        reportEncodings: reportEncodings,
        setReportEncodings: setReportEncodings,
      }}
    >
      {children}
    </ReportEncodingsContext.Provider>
  );
};

export default ReportEncodingsContextProvider;
