export default class ApiUri {
  static MMDZ = "/mmdz";

  static ADMIN = "/admin";
  static ACTIVATION = "/activation";
  static RESEND = "/resend";
  static SIGNUP = "/signup";
  static AUTH = "/auth";
  static IMAGE = "/image";
  static ACTIVATE = "/activate";
  static MY_ACCOUNT = "/my-account";
  static LOGIN = "/login";
  static USER_ME = "/me";
  static RBAC = "/rbac";
  static TOKEN_REFRESH = "/token/refresh";
  static INVITE_USER = "/invite-user";
  static UPLOAD = "/upload";
  static CHANGE_PASSWORD = "/change-password";
  static PASSWORD = "/password";
  static FORGOT = "/forgot";
  static BLOCKED_IPS = "/blocked-ips";
  static BLOCK = "/block";
  static UNBLOCK = "/unblock";
  static APPLICATION_ACCESS = "/application-access";
  static DELETE = "/delete";
  static TFA = "/tfa";
  static STATUS = "/status";
  static INFORMATION = "/information";
  static RESET = "/reset";
  static PUBLIC = "/public";
  static TRANSLATIONS = "/translations";
  static MAINTENANCE = "/maintenance";
  static INVITATION = "/invitation";
  static ACCEPT = "/accept";
  static RESET_PASSWORD_LINK = "/reset-password-link";

  static DATA = "/data";
  static TIMEZONE = "/timezone";
  static CURRENCY = "/currency";
  static REPORT_ENCODINGS = "/report-encodings";

  static UPDATE = "/update";
  static USER = "/user";
  static USERS = "/users";
  static ACCOUNTS_APP_REGISTRATION = "/accounts-app-registration";
  static PROFILE = "/profile";
  static SUB_PROFILE = "/sub-profile";
  static SELECT_PROFILE = "/select-profile";
  static SEARCH_PROFILE = "/search-profile";
  static CONNECT_PROFILE = "/connect-profile";
  static PROFILE_SOCIAL_MEDIA = "/social-media";
  static DELIVERY_SPLITS = "/delivery-splits";

  static COUNTRY = "/country";

  static READ_ALL = "/read-all";

  static FILE = "/file";

  static COMPOSITION = "/composition";
  static SUBMIT = "/submit";
  static TERRITORY = "/territory";

  static SONG = "/song";
  static VERSION_FROM_URL = "/version-from-url";
  static SONG_WRITER = "/song-writer";
  static PERFORMER = "/performer";
  static VERSION = "/version";
  static ARTWORK = "/artwork";
  static ARTWORK_FROM_URL = "/artwork-from-url";
  static PROCESS = "/process";
  static UNSUBMIT = "/unsubmit";
  static PUBLISH = "/publish";

  static SYNC = "/sync";
  static TAGS = "/tags";
  static INSTRUMENT = "/instrument";
  static LYRICAL_THEME = "/lyrical-theme";
  static GENRE = "/genre";
  static MOOD = "/mood";
  static TEMPO = "/tempo";
  static VOCALS = "/vocals";
  static TYPE = "/type";

  static PRO_AFFILIATION = "/pro-affiliation";

  static PUBLISHING = "/publishing";
  static PLATFORM = "/platform";
  static PLATFORM_IPI_NUMBER = "/platform-ipi-number";
  static LANGUAGE = "/language";
  static CUSTOM_LANGUAGE = "/custom-language";

  static FEED = "/feed";
}
