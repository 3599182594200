import { Check, Clear } from "@mui/icons-material";
import { Stack } from "@mui/material";
import React from "react";
import Colors from "../../../enums/colors";

const ApplicationButton = ({
  user,
  application,
  applicationName,
  handleClick,
}) => {
  let logoUrl = "";
  if (application === "mmdz") {
    logoUrl = "/MMDZ-logo-round.png";
  }
  if (application === "mmpz") {
    logoUrl = "/MMPZ-logo-round.png";
  }

  const containerSX = {
    "&:hover": {
      background: "#D1D4D5",
      cursor: "pointer",
    },
  };

  const active = user.user_applications.some(
    (app) => app?.application === application
  );
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={containerSX}
      onClick={() => handleClick(application, applicationName, active)}
      alignItems={"center"}
      justifyContent={"center"}
      padding={2}
    >
      <img
        className="app-icon"
        src={logoUrl}
        style={{ width: "50px", height: "50px" }}
      />
      <Stack alignItems={"start"} justifyContent={"start"} spacing={"0px"}>
        <p style={{ margin: 0 }}>{applicationName}</p>
        <p>
          {active ? (
            <Check sx={{ color: "green" }} />
          ) : (
            <Clear sx={{ color: "red" }} />
          )}
        </p>
      </Stack>
    </Stack>
  );
};

export default ApplicationButton;
