import {
  Grid,
  Paper,
  Box,
  Divider,
  TextField,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useContext } from "react";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import Colors from "../../../enums/colors";
import reportEncodings from "../../../context/report-encodings";
import ConfirmationDialogActivator from "../../../components/confirmation-dialog/confirmation-dialog-activator";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { useHistory } from "react-router-dom";
import { TranslationsContext } from "../../../context/translations";

const DangerZone = ({ id, config }) => {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const history = useHistory();

  const { translations } = useContext(TranslationsContext);

  const handleConfirm = () => {
    startGlobalLoader();
    API.delete(
      ApiUri.ADMIN + ApiUri.USER + ApiUri.DELETE + "/" + id,
      config
    ).then(
      (response) => {
        initSnackbarSuccess(
          translations["heading.closed"] ?? "User successfully deleted"
        );
        stopGlobalLoader();
        history.push("/users");
      },
      (error) => {
        initSnackbarError(error?.response?.data?.message);
        stopGlobalLoader();
      }
    );
  };

  const buttonSX = {
    backgroundColor: "#FF0000",
    "&:hover": {
      backgroundColor: "#CC0000",
    },
  };

  return (
    <Grid item xs={12} md={12}>
      <Paper elevation={3} sx={{ height: "100%" }}>
        <Box padding={2} height={"100%"}>
          <Stack
            direction={"row"}
            width={"100%"}
            height={"100%"}
            justifyContent={"space-around"}
            alignItems={"center"}
          >
            <ConfirmationDialogActivator
              onClick={handleConfirm}
              actionName={
                translations["button.delete_user"] ??
                "Delete User From MCZ Platform"
              }
              title={
                translations["button.delete_user"] ??
                "Delete User From MCZ Platform"
              }
              style={buttonSX}
              content={
                translations["help.confirm_delete"] ??
                "Warning: User will be deleted from MCZ platform. This action cannot be undone."
              }
            />
          </Stack>
        </Box>
      </Paper>
    </Grid>
  );
};

export default DangerZone;
