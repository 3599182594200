import Axios from "axios";
import * as SnackBar from "node-snackbar";
import "node-snackbar/dist/snackbar.css";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (window.location.pathname === "/accounts-app-registration") {
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      !window.location.pathname.startsWith("/mmdz") &&
      !window.location.pathname.startsWith("/mmpz") &&
      !window.location.pathname.startsWith("/mmaz")
    ) {
      SnackBar.show({
        showAction: false,
        text: "User unauthorized, redirecting...",
        pos: "bottom-center",
      });

      window.location.replace(process.env.REACT_APP_MMAZ_URL + "/");
    }

    if (error.response.status === 403) {
      if (error.response.data.error === "Token issue") {
        SnackBar.show({
          showAction: false,
          text: "Session expired",
          pos: "bottom-center",
        });
        // window.location.replace(
        //   process.env.REACT_APP_API_URL + ApiUri.TOKEN_REFRESH
        // );
      } else {
        return Promise.reject(error);
      }
    }

    if (error.response.status === 503) {
      window.location.replace(
        process.env.REACT_APP_MMAZ_URL + "/site-maintenance-mode"
      );
    }
    return Promise.reject(error);
  }
);

export default axios;
