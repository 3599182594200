import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { ListItemButton, Typography } from "@mui/material";
import { ListItemText } from "@mui/material";
import { Collapse, List } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Colors from "../enums/colors";
import { NavigationContext } from "../context/navigation";
import { Stack } from "@mui/system";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/auth";

const useStyles = makeStyles(() => ({
  navLink: {
    textDecoration: "none",
  },
  listItem: {
    color: Colors.white,
    minHeight: 30,
    "&:hover": {
      backgroundColor: Colors.darkerPrimary,
    },
    paddingTop: 0,
    paddingBottom: 0,
  },
  nestedListItem: {
    paddingLeft: 50,
  },
  icon: {
    alignContent: "center",
    paddingRight: 8,
    transform: "scale(0.75)",
  },
  text: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "17px",
  },
}));

export const deleteJwtCookie = (path = "/") => {

  const hostname = window.location.hostname;
  const domain = hostname.replace("mmaz", "");
  
  const cookieString = `${
    process.env.REACT_APP_MCZTOKEN_COOKIE
  }=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=${path};${
    domain ? ` Domain=${domain};` : ""
  }`;
  document.cookie = cookieString;
  document.cookie = cookieString + " Secure;";
  document.cookie = cookieString + " SameSite=None;";
};

export default function NavigationItem({ page, onOptionSelect }: any) {
  const classes = useStyles();
  // const { navigation, setNavigation } = useContext(NavigationContext);
  const history = useHistory();
  const { setUser, setJwt } = useContext(AuthContext);

  const handleLogout = () => {
    if (onOptionSelect) {
      onOptionSelect();
    }
    deleteJwtCookie();
    setUser(undefined);
    setJwt("");
    history.push("/");
  };

  const handleClick = () => {
    // const updatedNavigation = navigation.map((navigationPage: any) => {
    //   navigationPage.open = navigationPage.name === page.name;
    //   return navigationPage;
    // });
    // setNavigation(updatedNavigation);
    if (onOptionSelect) {
      onOptionSelect();
    }
    history.push(page.path);
  };

  // const handleChildClick = (child: any) => {
  //   const updatedNavigation = navigation.map((navigationPage: any) => {
  //     navigationPage.open = false;
  //     if (navigationPage.name === page.name) {
  //       navigationPage.open = true;
  //     }
  //     navigationPage.children.map((navigationPageChild: any) => {
  //       navigationPageChild.active = false;
  //       if (navigationPageChild.name === child.name) {
  //         navigationPageChild.active = true;
  //       }
  //       return navigationPageChild;
  //     });
  //     return navigationPage;
  //   });
  //   setNavigation(updatedNavigation);
  // };

  // if (page.children.length === 0) {
  if (page?.external) {
    return (
      <a href={page.path} target="_blank" className={classes.navLink}>
        <ListItemButton className={classes.listItem}>
          <Stack className={classes.icon}>{page.icon}</Stack>
          <Typography className={classes.text}>{page.name}</Typography>
        </ListItemButton>
      </a>
    );
  } else if (page?.function === "logout") {
    return (
      <ListItemButton onClick={handleLogout} className={classes.listItem}>
        <Stack className={classes.icon}>{page.icon}</Stack>
        <Typography className={classes.text}>{page.name}</Typography>
      </ListItemButton>
    );
  } else {
    return (
      <NavLink to={page.path} className={classes.navLink}>
        <ListItemButton onClick={handleClick} className={classes.listItem}>
          <Stack className={classes.icon}>{page.icon}</Stack>
          <Typography className={classes.text}>{page.name}</Typography>
        </ListItemButton>
      </NavLink>
    );
  }
  // }

  // return (
  //   <React.Fragment>
  //     <ListItemButton onClick={handleClick} className={classes.listItem}>
  //       <Stack className={classes.icon}>{page.icon}</Stack>
  //       <ListItemText>
  //         <Typography className={classes.text}>{page.name}</Typography>
  //       </ListItemText>
  //       {page.open ? "-" : "+"}
  //     </ListItemButton>

  // {
  /* {page.children.map((child: any, index: number) => (
        <NavLink key={index} to={child.path} className={classes.navLink}>
          <Collapse in={page.open} unmountOnExit>
            <List disablePadding>
              <ListItemButton
                onClick={() => handleChildClick(child)}
                className={classes.listItem + " " + classes.nestedListItem}
              >
                <ListItemText>
                  <Typography className={classes.text}>{child.name}</Typography>
                </ListItemText>
              </ListItemButton>
            </List>
          </Collapse>
        </NavLink>
      ))} */
  // }
  // </React.Fragment>
  // );
}
