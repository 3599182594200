import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { AuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import Account from "../../account";
import EditUserPage from "./EditUserPage";
import { getCookie } from "../../../App";

const EditUser = () => {
  const { id } = useParams();
  const { jwt } = useContext(AuthContext);
  const history = useHistory();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  const [originalUser, setCurrentUser] = useState();
  const [hasFetchedImage, setHasFetchedImage] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");

  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName ? `${profileName} - User` : "User";

  useEffect(() => {
    if (id) {
      startGlobalLoader();
      API.get(ApiUri.ADMIN + ApiUri.USER + "/" + id, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }).then(
        (response) => {
          const currentUser = response.data.data;
          API.get(
            ApiUri.ADMIN + ApiUri.USER + ApiUri.IMAGE + "/" + currentUser?.id,
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            }
          ).then(
            (response) => {
              setAvatarUrl(response?.data?.data?.image);
              setCurrentUser(currentUser);
              setHasFetchedImage(true);
              stopGlobalLoader();
            },
            (err) => {
              stopGlobalLoader();
            }
          );
        },
        (err) => {
          // initSnackbarError("You are not allowed to access this page.");
          stopGlobalLoader();
          history.push("/dashboard");
        }
      );
    } else {
      history.push("/dashboard");
    }
  }, []);
  //todo: fetch image from new endpoint
  return (
    <>
      {originalUser && hasFetchedImage && (
        <EditUserPage originalUser={originalUser} avatarUrl={avatarUrl} />
      )}
    </>
  );
};

export default EditUser;
