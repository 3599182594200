import {
  Grid,
  Paper,
  Box,
  Divider,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import React, { useContext } from "react";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import Colors from "../../enums/colors";
import { TranslationsContext } from "../../context/translations";

export const getPasswordValidationTexts = (translations) => {
  const passwordValidationTexts = translations["help.password"]?.split("<br>");
  const minimumPasswordLengthText = passwordValidationTexts
    ? passwordValidationTexts[0]
    : "* Minimum password length is 11 characters";
  const passwordsMatchingText = passwordValidationTexts
    ? passwordValidationTexts[1]
    : "* New and repeated new password must match";
  const atLeastOneDigitText = passwordValidationTexts
    ? passwordValidationTexts[2]
    : "* Password must contain at least one digit";
  const atLeastOneUppercaseText = passwordValidationTexts
    ? passwordValidationTexts[3]
    : "* Password must contain at least one uppercase letter";
  const atLeastOneLowercaseText = passwordValidationTexts
    ? passwordValidationTexts[4]
    : "* Password must contain at least one lowercase letter";
  const atLeastOneSpecialText = passwordValidationTexts
    ? passwordValidationTexts[5]
    : "* Password must contain at least one special character";
  return {
    minimumPasswordLengthText,
    passwordsMatchingText,
    atLeastOneDigitText,
    atLeastOneUppercaseText,
    atLeastOneLowercaseText,
    atLeastOneSpecialText,
  };
};

const PasswordSettings = ({
  currentPassword,
  handleEditPassword,
  newPassword,
  repeatNewPassword,
  passwordIsBeingEdited,
  newPasswordHasAtLeast11Characters,
  passwordsMatch,
  newPasswordHasAtLeastOneDigit,
  newPasswordHasAtLeastOneUppercaseLetter,
  newPasswordHasAtLeastOneLowercaseLetter,
  newPasswordHasAtLeastOneSpecialCharacter,
  handleSavePassword,
}) => {
  const { translations } = useContext(TranslationsContext);

  const {
    minimumPasswordLengthText,
    passwordsMatchingText,
    atLeastOneDigitText,
    atLeastOneUppercaseText,
    atLeastOneLowercaseText,
    atLeastOneSpecialText,
  } = getPasswordValidationTexts(translations);

  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ height: "100%" }}>
        <Box paddingX={2} paddingBottom={2} height={"100%"}>
          <Grid container height={"100%"}>
            <Grid item xs={12} md={12} paddingBottom={3}>
              <h1>
                {translations["heading.change_password"] ?? "Change Password"}
              </h1>
              <Divider />
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  type="password"
                  fullWidth
                  autoComplete="new-password"
                  label={
                    translations["label.current_password"] ?? "Current Password"
                  }
                  name="currentPassword"
                  value={currentPassword}
                  onChange={handleEditPassword}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  type="password"
                  fullWidth
                  label={translations["label.new_password"] ?? "New Password"}
                  name="newPassword"
                  value={newPassword}
                  onChange={handleEditPassword}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  type="password"
                  fullWidth
                  label={
                    translations["label.repeat_new_password"] ??
                    "Repeat New Password"
                  }
                  name="repeatNewPassword"
                  value={repeatNewPassword}
                  onChange={handleEditPassword}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography
                  fontSize="12px"
                  color={
                    passwordIsBeingEdited
                      ? newPasswordHasAtLeast11Characters
                        ? "#66bb6a"
                        : Colors.error
                      : Colors.grey
                  }
                >
                  {minimumPasswordLengthText}
                </Typography>
                <Typography
                  fontSize="12px"
                  color={
                    passwordIsBeingEdited
                      ? passwordsMatch
                        ? "#66bb6a"
                        : Colors.error
                      : Colors.grey
                  }
                >
                  {passwordsMatchingText}
                </Typography>
                <Typography
                  fontSize="12px"
                  color={
                    passwordIsBeingEdited
                      ? newPasswordHasAtLeastOneDigit
                        ? "#66bb6a"
                        : Colors.error
                      : Colors.grey
                  }
                >
                  {atLeastOneDigitText}
                </Typography>
                <Typography
                  fontSize="12px"
                  color={
                    passwordIsBeingEdited
                      ? newPasswordHasAtLeastOneUppercaseLetter
                        ? "#66bb6a"
                        : Colors.error
                      : Colors.grey
                  }
                >
                  {atLeastOneUppercaseText}
                </Typography>
                <Typography
                  fontSize="12px"
                  color={
                    passwordIsBeingEdited
                      ? newPasswordHasAtLeastOneLowercaseLetter
                        ? "#66bb6a"
                        : Colors.error
                      : Colors.grey
                  }
                >
                  {atLeastOneLowercaseText}
                </Typography>
                <Typography
                  fontSize="12px"
                  color={
                    passwordIsBeingEdited
                      ? newPasswordHasAtLeastOneSpecialCharacter
                        ? "#66bb6a"
                        : Colors.error
                      : Colors.grey
                  }
                >
                  {atLeastOneSpecialText}
                </Typography>
              </Grid>

              <Grid item xs={12} md={12}>
                <Stack direction="row" paddingY={2}>
                  <ActionButtonSmall
                    name="save"
                    sx={{ backgroundColor: "#667080" }}
                    onClick={handleSavePassword}
                    disabled={
                      passwordIsBeingEdited
                        ? !newPasswordHasAtLeast11Characters ||
                          !newPasswordHasAtLeastOneDigit ||
                          !passwordsMatch ||
                          !newPasswordHasAtLeastOneLowercaseLetter ||
                          !newPasswordHasAtLeastOneUppercaseLetter ||
                          !newPasswordHasAtLeastOneSpecialCharacter ||
                          currentPassword.length === 0
                        : true
                    }
                  >
                    {translations["button.save"] ?? "Save"}
                  </ActionButtonSmall>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default PasswordSettings;
