import React, { createContext, useState } from "react";
import { CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Colors from "../enums/colors";

export interface IGlobalLoaderContext {
  startGlobalLoader: any;
  stopGlobalLoader: any;
}

const useStyles = makeStyles(() => {
  return {
    contentEnabled: {
      display: "inital",
    },
    contentDisabled: {
      pointerEvents: "none",
      opacity: 0.2,
      transition: "opacity .6s ease-in-out",
    },
  };
});

export const GlobalLoaderContext = createContext({} as IGlobalLoaderContext);

const GlobalLoaderContextProvider = ({ children }: any) => {
  const classes = useStyles();
  const [loaderActive, setLoaderActive] = useState(false);

  const startGlobalLoader = () => {
    setLoaderActive(true);
  };

  const stopGlobalLoader = () => {
    setLoaderActive(false);
  };

  return (
    <GlobalLoaderContext.Provider
      value={{
        startGlobalLoader,
        stopGlobalLoader,
      }}
    >
      <React.Fragment>
        <div
          className={
            loaderActive ? classes.contentDisabled : classes.contentEnabled
          }
        >
          {children}
        </div>

        {loaderActive ? (
          <CircularProgress
            size={100}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              zIndex: 9999999,
              color: Colors.primary,
              marginLeft: -50,
              marginTop: -50,
            }}
          />
        ) : null}
      </React.Fragment>
    </GlobalLoaderContext.Provider>
  );
};

export default GlobalLoaderContextProvider;
