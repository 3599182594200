import React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function Loader() {
  return (
    <Box
      style={{ height: "100vh", marginTop: 100 }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
    >
      <CircularProgress
        size={80}
        color="primary"
        style={{ marginBottom: 20 }}
      />
    </Box>
  );
}
