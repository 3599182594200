import React from "react";
import { Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => {
  return {
    button: {
      width: "fit-content",
      minHeight: 36,
      textTransform: "none",
      borderRadius: 0,
    },
  };
});

export default function ActionButtonSmall(props: any) {
  const classes = useStyles();

  return (
    <Button
      // className={props.ignoreStyles ? "" : classes.button}
      {...props}
      variant={props.variant ? props.variant : "contained"}
      color={props.color ? props.color : "secondary"}
    >
      {props.children}
    </Button>
  );
}
