import React, { useContext, useEffect } from "react";
import "./App.scss";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Account from "./pages/account";
import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "./context/auth";
import BaseComponent from "./layout/base-component";
import PageTransition from "./layout/page-transition";
import Users from "./pages/users";
import BlockedIPs from "./pages/blocked-IPs";
import MaintenanceMode from "./pages/maintenance-mode";
import HelpZone from "./pages/help-zone";
import Login from "./pages/login";
import ForgotPassword from "./pages/login/forgot-password";
import ResendEmail from "./pages/login/resend-email";
import SignUp from "./pages/login/sign-up";
import EditUser from "./pages/users/user";
import { useParams } from "react-router";
import { SnackbarContext } from "./context/snackbar";
import { GlobalLoaderContext } from "./context/global-loader";
import ApiUri from "./api/api-uri";
import API from "./api/api";
import ChangePassword from "./pages/login/change-password";
import { TranslationsContext } from "./context/translations";
import { CircularProgress } from "@mui/material";
import Colors from "./enums/colors";
import { deleteJwtCookie } from "./layout/navigation-item";
import AcceptInvitationLogged from "./components/AcceptInvitationLogged";

// Utility functions to set cookies
export const setCookie = (name, value, days) => {
  const hostname = window.location.hostname;
  const domain = hostname.replace("mmaz", "");

  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  let domainAttribute = "";
  if (domain) {
    domainAttribute = "; domain=" + domain;
  }
  document.cookie =
    name + "=" + (value || "") + expires + domainAttribute + "; path=/; secure";
};

// Utility functions to get cookies
export const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

// Function to save new app and profile cookies
const handleAppProfileParams = (app, profile) => {
  if (app && profile) {
    setCookie(process.env.REACT_APP_MMAZ_APPLICATION_COOKIE, app, 365);
    setCookie(process.env.REACT_APP_MMAZ_PROFILE_COOKIE, profile, 365);
    setCookie(process.env.REACT_APP_MCZ_PROFILE_COOKIE, profile, 365);
    return true;
  }
  return false;
};

// Component to redirect to the same page and remove app and profile from URL
const RedirectHandlerToRemoveAppAndProfile = () => {
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const parts = pathname.split("/").filter((part) => part);

  let app, profile;
  if (parts.length >= 2) {
    app = parts[0];
    profile = parts[1];
  }

  const paramsHandled = handleAppProfileParams(app, profile);

  useEffect(() => {
    if (paramsHandled) {
      const newPath = `/${parts.slice(2).join("/")}`;
      history.replace(newPath);
    }
  }, [paramsHandled, history, parts]);

  return null;
};

const Logout = () => {
  const history = useHistory();
  const { setUser, setJwt } = useContext(AuthContext);
  deleteJwtCookie();
  setUser(undefined);
  setJwt("");
  history.push("/");
  return "";
};

// Component to save new params and return login page
const RedirectToLogin = () => {
  const history = useHistory();
  const { app, profile } = useParams();
  const validApp = app === "mmdz" || app === "mmpz" || app === "mmaz";
  if (validApp) {
    handleAppProfileParams(app, profile);
  } else {
    history.push("/");
  }

  return validApp ? <Login app={app} profile={profile} /> : null;
};

// Component to detect if the user should see the forgot password or change password pages
const RedirectToForgotPasswordOrChangePassword = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hasParams = Array.from(searchParams).length > 0;

  return hasParams ? <ChangePassword /> : <ForgotPassword />;
};

// Component to redirect to login and add app and profile to URL
const RedirectHandlerEmptyRoute = () => {
  const history = useHistory();
  const appCookie = getCookie(process.env.REACT_APP_MMAZ_APPLICATION_COOKIE);
  const profileCookie = getCookie(process.env.REACT_APP_MMAZ_PROFILE_COOKIE);

  if (!appCookie || !profileCookie) {
    setCookie(process.env.REACT_APP_MMAZ_APPLICATION_COOKIE, "mmdz", 365);
    setCookie(
      process.env.REACT_APP_MMAZ_PROFILE_COOKIE,
      "horus-music-limited",
      365
    );
    setCookie(
      process.env.REACT_APP_MCZ_PROFILE_COOKIE,
      "horus-music-limited",
      365
    );
  }
  history.push(
    `/${appCookie ?? "mmdz"}/${profileCookie ?? "horus-music-limited"}`
  );
  return null;
};

// Component to activate a new account and redirect to login
const ActivateHandler = () => {
  const history = useHistory();
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { profile, token, email, app } = useParams();

  const redirectToLoginError = () => {
    initSnackbarError("Something went wrong");
    history.push(`/${app}/${profile}`);
  };

  useEffect(() => {
    if (token && email) {
      startGlobalLoader();
      const formData = {
        email: email,
        token: token,
      };
      API.post(ApiUri.AUTH + ApiUri.ACTIVATE, formData).then(
        (response) => {
          stopGlobalLoader();
          initSnackbarSuccess(response.data.data.message);
          history.push(`/${app}/${profile}`);
        },
        (error) => {
          stopGlobalLoader();
          initSnackbarError(error);
          history.push(`/${app}/${profile}`);
        }
      );
    } else {
      redirectToLoginError();
    }
  }, []);

  return <></>;
};

const App = () => {
  const { user, jwt } = useContext(AuthContext);

  const { hasFetchedTranslations, setHasFetchedTranslations, setTranslations } =
    useContext(TranslationsContext);

  const currentUrl = window.location.href;

  const onConfirmActivationURL = currentUrl.startsWith(
    process.env.REACT_APP_MMAZ_URL + "/activation/confirm/"
  );

  const onChangePasswordURL = currentUrl.startsWith(
    process.env.REACT_APP_MMAZ_URL + "/forgotten-password/"
  );

  useEffect(() => {
    const appCookie = getCookie(process.env.REACT_APP_MMAZ_APPLICATION_COOKIE);
    const profileCookie = getCookie(process.env.REACT_APP_MMAZ_PROFILE_COOKIE);

    if (!appCookie || !profileCookie) {
      setCookie(process.env.REACT_APP_MMAZ_APPLICATION_COOKIE, "mmdz", 365);
      setCookie(
        process.env.REACT_APP_MMAZ_PROFILE_COOKIE,
        "horus-music-limited",
        365
      );
      setCookie(
        process.env.REACT_APP_MCZ_PROFILE_COOKIE,
        "horus-music-limited",
        365
      );
    }
  }, []);

  useEffect(() => {
    if (jwt && user && !hasFetchedTranslations) {
      API.get(ApiUri.PUBLIC + ApiUri.TRANSLATIONS, {
        params: {
          locale: (user as any)?.user_profile?.language?.code ?? "en",
        },
      }).then(
        (response) => {
          setHasFetchedTranslations(true);
          setTranslations(response.data.data);
        },
        (error) => {
          setHasFetchedTranslations(true);
        }
      );
    }
  }, [jwt, user]);

  return jwt && !onConfirmActivationURL && !onChangePasswordURL ? (
    user && hasFetchedTranslations ? (
      <BrowserRouter>
        <BaseComponent>
          <Switch style={{ width: "100%", height: "100%" }}>
            <PrivateRoute
              path="/users/user/:id"
              component={PageTransition(EditUser)}
            />
            <PrivateRoute
              path="/accept-invitation/:app"
              component={PageTransition(AcceptInvitationLogged)}
            />
            <Route
              path="/:app/:profile/*"
              component={RedirectHandlerToRemoveAppAndProfile}
            />
            <Redirect exact from="/" to="/dashboard" />
            <PrivateRoute path="/users" component={PageTransition(Users)} />
            <PrivateRoute
              path="/dashboard"
              component={PageTransition(Dashboard)}
            />
            <PrivateRoute
              path="/my-account"
              component={PageTransition(Account)}
            />
            <PrivateRoute
              path="/blocked-ips"
              component={PageTransition(BlockedIPs)}
            />
            <PrivateRoute
              path="/maintenance-mode"
              component={PageTransition(MaintenanceMode)}
            />
            <PrivateRoute
              path="/help-zone"
              component={PageTransition(HelpZone)}
            />
            <PrivateRoute path="/logout" component={Logout} />
            <Redirect from="*" to="/dashboard" />
            {/* <Route path="*" component={PageTransition(NotFound)} /> */}
          </Switch>
        </BaseComponent>
      </BrowserRouter>
    ) : (
      <CircularProgress
        size={100}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          zIndex: 9999999,
          color: Colors.primary,
          marginLeft: -50,
          marginTop: -50,
        }}
      />
    )
  ) : (
    <BrowserRouter>
      <Switch>
        <Route
          path="/accept-invitation/:app"
          component={PageTransition(AcceptInvitationLogged)}
        />
        <Route
          path="/activation/confirm/:app/:profile/:token/:email"
          component={ActivateHandler}
        />
        <Route
          path="/:app/:profile/forgotten-password"
          component={ForgotPassword}
        />
        <Route path="/forgotten-password/:profile" component={ChangePassword} />
        <Route
          path="/:app/:profile/activation/resend"
          component={ResendEmail}
        />
        <Route path="/:app/signup/:profile" component={SignUp} />
        <Route path="/:app/:profile" component={RedirectToLogin} />
        <Route path="/" component={RedirectHandlerEmptyRoute} />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
