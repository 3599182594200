import { combineComponents } from "./context/combine-components";
import AuthContextProvider from "./context/auth";
import GlobalLoaderContextProvider from "./context/global-loader";
import SnackbarContextProvider from "./context/snackbar";
import LanguagesContextProvider from "./context/languages";
import CountriesContextProvider from "./context/countries";
import TimezonesContextProvider from "./context/timezones";
import CurrenciesContextProvider from "./context/currencies";
import ReportEncodingsContextProvider from "./context/report-encodings";
import TranslationsContextProvider from "./context/translations";
import InformationContextProvider from "./context/information";

const providers = [
  AuthContextProvider,
  LanguagesContextProvider,
  CountriesContextProvider,
  TimezonesContextProvider,
  CurrenciesContextProvider,
  ReportEncodingsContextProvider,
  GlobalLoaderContextProvider,
  SnackbarContextProvider,
  TranslationsContextProvider,
  InformationContextProvider,
];
export const AppContextProvider = combineComponents(...providers);
