import { Grid, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import ApplicationButton from "./ApplicationButton";
import ConfirmToggleApplicationModal from "./ConfirmToggleApplicationModal";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { AuthContext } from "../../../context/auth";
import { TranslationsContext } from "../../../context/translations";

const UserHeader = ({ user, setUser, originalUser }) => {
  const [selectedApplication, setSelectedApplication] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);

  const { jwt } = useContext(AuthContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const { translations } = useContext(TranslationsContext);

  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  const updateApps = (userToBeModified) => {
    let newUser = userToBeModified;
    if (selectedApplication.newStatus === "grant") {
      newUser.user_applications.push({
        application: selectedApplication.application,
      });
      return newUser;
    } else if (selectedApplication.newStatus === "revoke") {
      newUser.user_applications = newUser.user_applications.filter(
        (application) =>
          application.application !== selectedApplication.application
      );
      return newUser;
    }
  };

  const handleClickButton = (application, applicationName, revoke) => {
    setSelectedApplication({
      application: application,
      applicationName: applicationName,
      newStatus: revoke ? "revoke" : "grant",
      id: application === "mmdz" ? 0 : 1,
    });
    setOpenModal(true);
  };

  const handleConfirm = () => {
    startGlobalLoader();

    const payload = { application: selectedApplication.application };
    API.post(
      ApiUri.ADMIN + ApiUri.USER + ApiUri.APPLICATION_ACCESS + "/" + user.id,
      payload,
      config
    ).then(
      (response) => {
        const newUserState = updateApps(user);
        setUser(newUserState);
        // initSnackbarSuccess(
        //   `User application access successfully ${
        //     selectedApplication?.newStatus === "revoke"
        //       ? "revoked from"
        //       : "granted to"
        //   } ${selectedApplication?.applicationName}`
        // );
        setOpenModal(false);
        setSelectedApplication(null);
        stopGlobalLoader();
      },
      (error) => {
        setOpenModal(false);
        setSelectedApplication(null);
        initSnackbarError(error?.response?.data?.message);
        stopGlobalLoader();
      }
    );

    // const newUserToBeSaved = updateApps(originalUser);

    // API.put(
    //   ApiUri.ADMIN + ApiUri.USER + ApiUri.UPDATE + "/" + user.id,
    //   newUserToBeSaved,
    //   config
    // ).then(
    //   (response) => {
    //     const newUserState = updateApps(user);
    //     setUser(newUserState);
    //     initSnackbarSuccess(
    //       `User application access successfully ${
    //         selectedApplication?.newStatus === "revoke"
    //           ? "revoked from"
    //           : "granted to"
    //       } ${selectedApplication?.applicationName}`
    //     );
    //     setOpenModal(false);
    //     setSelectedApplication(null);
    //     stopGlobalLoader();
    //   },
    //   (error) => {
    //     setOpenModal(false);
    //     setSelectedApplication(null);
    //     initSnackbarError(error?.response?.data?.message);
    //     stopGlobalLoader();
    //   }
    // );
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <h1>
            {`${user.user_profile.first_name} ${user.user_profile.last_name}`}
          </h1>
          <Stack direction={"row"} spacing={3} paddingTop={1}>
            <ApplicationButton
              user={user}
              application={"mmdz"}
              applicationName={
                translations["title.mmdz"] ?? "My Music Distribution Zone"
              }
              handleClick={handleClickButton}
            />
            <ApplicationButton
              user={user}
              application={"mmpz"}
              applicationName={
                translations["title.mmpz"] ?? "My Music Publishing Zone"
              }
              handleClick={handleClickButton}
            />
          </Stack>
        </Stack>
      </Grid>
      <ConfirmToggleApplicationModal
        modalOpened={openModal}
        handleCloseModal={() => setOpenModal(false)}
        selectedApplication={selectedApplication}
        handleConfirm={handleConfirm}
        user={user}
      />
    </>
  );
};

export default UserHeader;
