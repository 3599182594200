import React, { createContext, useState } from "react";
import "node-snackbar/dist/snackbar.css";
import { Language } from "../models/language/language";

export interface ILanguagesContext {
  languages: Language[];
  setLanguages: React.Dispatch<React.SetStateAction<Language[]>>;
}

export const LanguagesContext = createContext({} as ILanguagesContext);

const LanguagesContextProvider = ({ children }: any) => {
  const [languages, setLanguages] = useState<Language[]>([]);

  return (
    <LanguagesContext.Provider
      value={{
        languages,
        setLanguages,
      }}
    >
      {children}
    </LanguagesContext.Provider>
  );
};

export default LanguagesContextProvider;
