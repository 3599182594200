import React, { useContext, useEffect, useState } from "react";
import ApiUri from "../../api/api-uri";
import API from "../../api/api";
import { AuthContext } from "../../context/auth";
import { ContentCopy } from "@mui/icons-material";
import {
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";

const PasswordResetLinkField = ({ account }) => {
  const { jwt } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [resetURL, setResetURL] = useState("");

  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  useEffect(() => {
    API.get(
      ApiUri.ADMIN +
        ApiUri.USER +
        ApiUri.RESET_PASSWORD_LINK +
        "/" +
        account?.id,
      config
    ).then(
      (response) => {
        setResetURL(response.data.data.url);
        setIsLoading(false);
      },
      (error) => {}
    );
  }, []);

  const copy = () => {
    const textField: any = document.getElementById("passwordResetLink");
    if (textField) {
      const textFieldValue = textField.value;
      navigator.clipboard.writeText(textFieldValue);
    }
  };

  return (
    <TextField
      fullWidth
      value={resetURL}
      id="passwordResetLink"
      disabled
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading ? (
              <CircularProgress />
            ) : (
              <IconButton edge="end" onClick={copy} aria-label="copy">
                <ContentCopy />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordResetLinkField;
