import React, { useContext } from "react";
import List from "@mui/material/List";
import NavigationItem from "./navigation-item";
import HomeIcon from "@mui/icons-material/Home";
import {
  Block,
  Group,
  Handyman,
  Help,
  Person,
  EventNote,
  Logout,
} from "@mui/icons-material";
import { TranslationsContext } from "../context/translations";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../context/auth";

export default function Navigation({ onOptionSelect }) {
  const { user } = useContext(AuthContext);
  const { translations } = useContext(TranslationsContext);
  const location = useLocation();

  return (
    <React.Fragment>
      <List>
        <NavigationItem
          key={0}
          page={{
            path: "/dashboard",
            name: translations["title.dashboard"] ?? "Dashboard",
            icon: <HomeIcon />,
            open: "/dashboard" === location.pathname,
            children: [],
          }}
          onOptionSelect={onOptionSelect}
        />
        <NavigationItem
          key={1}
          page={{
            path: "/my-account",
            name: translations["title.my_account"] ?? "Account",
            icon: <Person />,
            open: "/my-account" === location.pathname,
            children: [],
          }}
          onOptionSelect={onOptionSelect}
        />
        {user?.user_role === 1 && (
          <>
            <NavigationItem
              key={2}
              page={{
                path: "/users",
                name: translations["title.users"] ?? "Users",
                icon: <Group />,
                open: "/users" === location.pathname,
                children: [],
              }}
              onOptionSelect={onOptionSelect}
            />
            <NavigationItem
              key={3}
              page={{
                path: "/blocked-ips",
                name: translations["title.blocked_ips"] ?? "Blocked IP's",
                icon: <Block />,
                open: "/blocked-ips" === location.pathname,
                children: [],
              }}
              onOptionSelect={onOptionSelect}
            />
            <NavigationItem
              key={4}
              page={{
                path: "/maintenance-mode",
                name:
                  translations["title.maintenance_mode"] ?? "Maintenance Mode",
                icon: <Handyman />,
                open: "/maintenance-mode" === location.pathname,
                children: [],
              }}
              onOptionSelect={onOptionSelect}
            />
            <NavigationItem
              key={5}
              page={{
                path: process.env.REACT_APP_KIBANA_URL + "/login?next=%2F",
                external: true,
                name: translations["title.event_logger"] ?? "Event Logger",
                icon: <EventNote />,
                open: false,
                children: [],
              }}
              onOptionSelect={onOptionSelect}
            />
          </>
        )}

        {user?.user_role === 0 && (
          <NavigationItem
            key={6}
            page={{
              path: "/help-zone",
              name: translations["button.help"] ?? "Help",
              icon: <Help />,
              open: "/help-zone" === location.pathname,
              children: [],
            }}
            onOptionSelect={onOptionSelect}
          />
        )}
        <NavigationItem
          key={7}
          page={{
            path: "/logout",
            name: translations["button.logout"] ?? "Logout",
            icon: <Logout />,
            open: false,
            children: [],
            function: "logout",
          }}
          onOptionSelect={onOptionSelect}
        />
      </List>
    </React.Fragment>
  );
}
