import {
  Grid,
  Paper,
  Box,
  Divider,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useContext } from "react";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { TranslationsContext } from "../../../context/translations";

const UserAccess = ({
  account,
  handleEdit,
  roles,
  statuses,
  formIsValid,
  handleSaveProfile,
}) => {
  const { translations } = useContext(TranslationsContext);

  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ height: "100%" }}>
        <Box paddingX={2} paddingBottom={2}>
          <Grid container>
            <Grid item xs={12} md={12} paddingBottom={3}>
              <h1>{translations["heading.user_access"] ?? "User Access"}</h1>
              <Divider />
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel>
                    {translations["heading.user_role"] ?? "Role"}
                  </InputLabel>
                  <Select
                    name="role"
                    value={account.user_role}
                    onChange={handleEdit}
                    label={translations["heading.user_role"] ?? "Role"}
                    // disabled
                  >
                    {roles?.map((role) => (
                      <MenuItem value={role?.id} key={role?.id}>
                        {role?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel>
                    {translations["heading.status"] ?? "Status"}
                  </InputLabel>
                  <Select
                    name="status"
                    value={account.status}
                    onChange={handleEdit}
                    label={translations["heading.status"] ?? "Status"}
                  >
                    {statuses?.map((status) => (
                      <MenuItem value={status?.id} key={status?.id}>
                        {status?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <Stack direction="row" paddingY={2}>
                  <ActionButtonSmall
                    name="save"
                    sx={{ backgroundColor: "#667080" }}
                    onClick={() =>
                      handleSaveProfile(
                        translations["flash_message.preferences_updated"]
                      )
                    }
                    disabled={!formIsValid}
                  >
                    {translations["button.save"] ?? "Save"}
                  </ActionButtonSmall>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default UserAccess;
