import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Stack,
  Slide,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import { TranslationsContext } from "../../context/translations";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import { ReactCrop } from "../../components/image-crop/ReactCrop";
import { Crop } from "../../components/image-crop/types";
import { centerCrop, makeAspectCrop } from "../../components/image-crop/utils";

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageCropModal = ({
  modalOpened,
  handleCloseModal,
  imageToBeCropped,
  handleConfirm,
}) => {
  const { translations } = useContext(TranslationsContext);
  const [crop, setCrop] = useState<Crop>({
    unit: "px",
    x: 100,
    y: 100,
    width: 50,
    height: 50,
  });
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        1,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
    setImageRef(e.currentTarget);
  };

  const handleSave = async () => {
    if (!crop || !imageRef) {
      return;
    }

    const canvas = document.createElement("canvas");
    const scaleX = imageRef.naturalWidth / 100;
    const scaleY = imageRef.naturalHeight / 100;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      console.error("Failed to get 2D context");
      return;
    }

    ctx.drawImage(
      imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    canvas.toBlob(
      (blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob);
          console.log(url);
          handleConfirm(blob, url);
        }
      },
      "image/jpeg",
      1
    );
  };

  return (
    <Dialog
      open={modalOpened}
      onClose={handleCloseModal}
      TransitionComponent={Transition}
      maxWidth="md"
    >
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" marginLeft={2} flex={1}>
            {translations["modal_title.profile_picture"] ??
              "Change Profile Picture"}
          </Typography>
          <IconButton
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box padding={5}>
        <Stack spacing={3}>
          <Box>
            <ReactCrop
              crop={crop}
              onChange={(crop, percentCrop) => setCrop(percentCrop)}
              aspect={1}
            >
              <img
                src={imageToBeCropped}
                onLoad={onImageLoad}
                style={{ maxHeight: "60vh" }}
              />
            </ReactCrop>
          </Box>
          <Stack direction={"row"} spacing={2}>
            <ActionButtonSmall onClick={handleCloseModal} color={"error"}>
              {translations["button.close"] ?? "Close"}
            </ActionButtonSmall>
            <ActionButtonSmall onClick={handleSave} color={"success"}>
              {translations["button.change"] ?? "Change"}
            </ActionButtonSmall>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ImageCropModal;
