import React, { useContext } from "react";
import {
  Box,
  Fab,
  Grid,
  IconButton,
  Menu,
  Stack,
  Tooltip,
} from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AuthContext, IAuthContext } from "../context/auth";
import {
  GlobalLoaderContext,
  IGlobalLoaderContext,
} from "../context/global-loader";
import { getCookie } from "../App";
import { TranslationsContext } from "../context/translations";
import AppIcon from "../components/app-icon";

export default function AppSelector() {
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader } =
    useContext<IGlobalLoaderContext>(GlobalLoaderContext);
  const [serviceAppMenuAnchorEl, setServiceAppMenuAnchorEl] =
    React.useState(null);

  const whiteLabel = getCookie(process.env.REACT_APP_WHITE_LABEL_COOKIE);

  //todo: use value from env
  const domainUrl = getCookie("DOMAIN_URL");

  const { translations } = useContext(TranslationsContext);

  const showMMDZ = (user as any).user_applications.some(
    (app) => app?.application === "mmdz"
  );

  const showMMPZ = (user as any)?.user_applications.some(
    (app) => app?.application === "mmpz"
  );

  const handleServiceAppMenuClick = (event: any) => {
    setServiceAppMenuAnchorEl(event.currentTarget);
  };

  const handleServiceAppMenuClose = () => {
    setServiceAppMenuAnchorEl(null);
  };

  const handleAppSelect = () => {
    handleServiceAppMenuClose();
    startGlobalLoader();
  };

  const profileCookie = getCookie(process.env.REACT_APP_MCZ_PROFILE_COOKIE);
  const profileTypeCookie = getCookie(
    process.env.REACT_APP_MCZ_PROFILE_TYPE_COOKIE
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ marginRight: "20px" }}
    >
      <IconButton
        color="inherit"
        onClick={handleServiceAppMenuClick}
        size="large"
      >
        <AppsIcon />
      </IconButton>

      <Menu
        anchorEl={serviceAppMenuAnchorEl}
        keepMounted
        open={Boolean(serviceAppMenuAnchorEl)}
        onClose={handleServiceAppMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Grid container maxWidth={"300px"}>
          {!whiteLabel ? (
            <>
              <Grid
                item
                xs={!showMMDZ && !showMMPZ ? 12 : 6}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Stack width={"100%"} justifyContent={"center"}>
                  <AppIcon
                    imageSource="/MMAZ-logo-round.png"
                    appName={
                      translations["title.mmaz"] ?? "My Music Account Zone"
                    }
                    linkUrl="/my-account"
                    small
                  />
                </Stack>
              </Grid>
              {showMMDZ && (
                <Grid
                  item
                  xs={6}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Stack width={"100%"} justifyContent={"center"}>
                    <AppIcon
                      imageSource="/MMDZ-logo-round.png"
                      appName={
                        translations["title.mmdz"] ??
                        "My Music Distribution Zone"
                      }
                      linkUrl={process.env.REACT_APP_MMDZ_URL + "/"}
                      small
                    />
                  </Stack>
                </Grid>
              )}
              {showMMPZ && (
                <Grid
                  item
                  xs={6}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Stack width={"100%"} justifyContent={"center"}>
                    <AppIcon
                      imageSource="/MMPZ-logo-round.png"
                      appName={
                        translations["title.mmpz"] ?? "My Music Publishing Zone"
                      }
                      linkUrl={
                        process.env.REACT_APP_MMPZ_URL +
                        `/dashboard?parent=${
                          profileCookie ?? ""
                        }&profile_type=${profileTypeCookie ?? ""}`
                      }
                      small
                    />
                  </Stack>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={6} justifyContent={"center"} alignItems={"center"}>
                <Stack width={"100%"} justifyContent={"center"}>
                  <AppIcon
                    imageSource="/MMAZ-logo-round.png"
                    appName={
                      translations["title.mmaz"] ?? "My Music Account Zone"
                    }
                    linkUrl="/my-account"
                    small
                  />
                </Stack>
              </Grid>
              {showMMDZ && (
                <Grid
                  item
                  xs={6}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Stack width={"100%"} justifyContent={"center"}>
                    <AppIcon
                      imageSource="/MMDZ-logo-round.png"
                      appName={
                        translations["title.mmdz"] ??
                        "My Music Distribution Zone"
                      }
                      linkUrl={`https://mmdz.${domainUrl}`}
                      small
                    />
                  </Stack>
                </Grid>
              )}
              {/* {showMMPZ && (
                <Grid
                  item
                  xs={6}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Stack width={"100%"} justifyContent={"center"}>
                    <AppIcon
                      imageSource="/MMPZ-logo-round.png"
                      appName={
                        translations["title.mmpz"] ?? "My Music Publishing Zone"
                      }
                      linkUrl={process.env.REACT_APP_MMPZ_URL + "/"}
                      small
                    />
                  </Stack>
                </Grid>
              )} */}
            </>
          )}
        </Grid>
      </Menu>
    </Box>
  );
}
