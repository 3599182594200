import React, { useContext, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { StyledEngineProvider } from "@mui/material/styles";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Navigation from "./navigation";
import Colors from "./../enums/colors";
import NavigationContextProvider from "../context/navigation";
import ConfirmationDialogContext from "../context/confirmation-dialog-context";
import UserProfileDropdown from "./user-profile-dropdown";
import { TranslationsContext } from "../context/translations";
import ApiUri from "../api/api-uri";
import API from "../api/api";
import { AuthContext } from "../context/auth";
import AppSelector from "./app-selector";
import { InformationContext } from "../context/information";
import { getCookie } from "../App";

const drawerWidth = 195;

function BaseComponent(props: any) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleCloseDrawer = () => {
    setMobileOpen(false);
  };
  const {
    translations,
    setTranslations,
    hasFetchedTranslations,
    setHasFetchedTranslations,
  } = useContext(TranslationsContext);

  const { information } = useContext(InformationContext);

  const { user } = useContext(AuthContext);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const drawer = (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        // Uncomment this to bring back the image
        // style={{ paddingBottom: 10, paddingTop: 10 }}
        style={{ height: "48px" }}
      >
        {/* <img src="/avatar-logo.jpeg" alt="" width="100%" loading="eager" /> */}
        {/* <Person sx={{ width: "100px", height: "100px", color: "white" }} /> */}
      </Box>

      <NavigationContextProvider>
        <Navigation onOptionSelect={handleCloseDrawer} />
      </NavigationContextProvider>
    </div>
  );

  const whiteLabel = getCookie(process.env.REACT_APP_WHITE_LABEL_COOKIE);
  const appName = getCookie("APP_NAME");
  const web = getCookie("WEB");

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <StyledEngineProvider injectFirst>
      <ConfirmationDialogContext>
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            width: "100%",
          }}
        >
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{ backgroundColor: Colors.primary, boxShadow: "none" }}
          >
            <Toolbar variant="dense" disableGutters>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ marginRight: 2, paddingLeft: "20px" }}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <UserProfileDropdown />
              <AppSelector />
            </Toolbar>
          </AppBar>

          <nav>
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                PaperProps={{
                  sx: {
                    width: drawerWidth,
                    backgroundColor: Colors.primary,
                    borderRight: "none",
                  },
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  {drawer}
                  <Box sx={{ flexGrow: 1 }} />
                  <Box sx={{ p: 2 }}>
                    {whiteLabel ? (
                      <></>
                    ) : (
                      <Typography fontSize={15} color="white">
                        <a
                          style={{ color: "#3399FE" }}
                          href="https://www.myclientzone.com/"
                          target="_blank"
                        >
                          MyClientZone v5.0
                        </a>{" "}
                        - Copyright © {currentYear} Nick Dunn Enterprises Ltd.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Drawer>
            </Hidden>

            <Hidden smDown implementation="css">
              <Drawer
                PaperProps={{
                  sx: {
                    width: drawerWidth,
                    backgroundColor: Colors.primary,
                    borderRight: "none",
                  },
                }}
                variant="permanent"
                open
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  {drawer}
                  <Box sx={{ flexGrow: 1 }} />
                  <Box sx={{ p: 2 }}>
                    {whiteLabel ? (
                      <></>
                    ) : (
                      <Typography fontSize={15} color="white">
                        <a
                          style={{ color: "#3399FE" }}
                          href="https://www.myclientzone.com/"
                          target="_blank"
                        >
                          MyClientZone v5.0
                        </a>{" "}
                        - Copyright © {currentYear} Nick Dunn Enterprises Ltd.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Drawer>
            </Hidden>
          </nav>

          <main
            style={{
              flexGrow: 1,
              marginLeft: isSmallScreen ? 0 : drawerWidth,
              marginTop: "48px",
              padding: "16px",
              minHeight: "100%",
              width: "100%",
            }}
          >
            {props.children}
          </main>
        </div>
      </ConfirmationDialogContext>
    </StyledEngineProvider>
  );
}

export default BaseComponent;
