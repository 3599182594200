import React, { createContext, useState } from "react";
import "node-snackbar/dist/snackbar.css";

export interface ITimezonesContext {
  timezones: any;
  setTimezones: any;
}

export const TimezonesContext = createContext({} as ITimezonesContext);

const TimezonesContextProvider = ({ children }: any) => {
  const [timezones, setTimezones] = useState<any>([]);

  return (
    <TimezonesContext.Provider
      value={{
        timezones: timezones,
        setTimezones: setTimezones,
      }}
    >
      {children}
    </TimezonesContext.Provider>
  );
};

export default TimezonesContextProvider;
