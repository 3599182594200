import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Stack,
  Slide,
} from "@mui/material";
import React, { useContext } from "react";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import Colors from "../../../enums/colors";
import { TranslationsContext } from "../../../context/translations";

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmToggleApplicationModal = ({
  modalOpened,
  handleCloseModal,
  selectedApplication,
  handleConfirm,
  user,
}) => {
  const { translations } = useContext(TranslationsContext);

  const baseText =
    selectedApplication?.newStatus === "revoke"
      ? translations["user.revoke_application_access_confirmation"] ??
        "<b>%username% (%email%)</b> currently has access to <b>%application%</b>. Revoking access means the user will no longer be able to access this application.<br/><br/>Are you sure you want to proceed with revoking access from %application% for this user?"
      : translations["user.grant_application_access_confirmation"] ??
        "<b>%username% (%email%)</b> currently does not have access to <b>%application%</b>. Granting access means the user will be able to access this application.<br/><br/>Are you sure you want to proceed with giving access to %application% for this user?";

  return (
    <Dialog
      open={modalOpened}
      onClose={handleCloseModal}
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" marginLeft={2} flex={1}>
            Confirm User Application Access
          </Typography>
          <IconButton
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box padding={5}>
        <Stack spacing={5}>
          <Stack spacing={2}>
            <Typography
              align="center"
              sx={{
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              {selectedApplication?.applicationName}
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: baseText
                  .replace(
                    "%username%",
                    `${user.user_profile.first_name} ${user.user_profile.last_name}`
                  )
                  .replace("%email%", user.email)
                  .replace(
                    /%application%/g,
                    selectedApplication?.applicationName
                  ),
              }}
            />
          </Stack>

          <Stack
            direction={"row"}
            justifyContent={"space-around"}
            width={"100%"}
          >
            <ActionButtonSmall
              onClick={handleCloseModal}
              sx={{ backgroundColor: Colors.background }}
            >
              {translations["button.cancel"] ?? "Cancel"}
            </ActionButtonSmall>
            <ActionButtonSmall
              onClick={handleConfirm}
              sx={{
                backgroundColor:
                  selectedApplication?.newStatus === "revoke"
                    ? Colors.error
                    : "#4CAF50",
              }}
            >
              {selectedApplication?.newStatus === "revoke"
                ? translations["button.revoke_access"] ?? "Revoke Access"
                : translations["button.grant_access"] ?? "Grant Access"}
            </ActionButtonSmall>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ConfirmToggleApplicationModal;
