import React, { useContext } from "react";
import { Box, Divider, Menu, MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { AuthContext } from "../context/auth";
import Colors from "../enums/colors";
import SmallAvatar from "../components/avatars/small-avatar";
import { Link } from "react-router-dom";
import { Link as MUILink } from "@mui/material";
import { useHistory } from "react-router-dom";
import { deleteJwtCookie } from "./navigation-item";

const useStyles = makeStyles(() => {
  return {
    userProfileDropdownBox: {
      marginRight: 5,
      "&:hover": {
        cursor: "pointer",
      },
    },
    link: {
      textDecoration: "none",
    },
  };
});

export default function UserProfileDropdown() {
  const classes = useStyles();
  const { user, setUser, userImageUrl, setJwt } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    deleteJwtCookie();
    setUser(undefined);
    setJwt("");
    history.push("/");
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        className={classes.userProfileDropdownBox}
        onClick={handleClick}
      >
        <SmallAvatar alt={user?.user_profile.first_name} src={userImageUrl} />
        {/* <h5 color={Colors.white} style={{ marginLeft: 5 }}> */}
        <Typography sx={{ marginLeft: 1 }}>
          {user?.user_profile.first_name} {user?.user_profile.last_name}
          {/* Account Name */}
        </Typography>
        {/* </h5> */}
        <ArrowDropDownIcon />
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{ top: 5 }}
      >
        <MenuItem onClick={handleClose}>
          <Link className={classes.link} to={"/my-account"}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <AccountCircleRoundedIcon color="primary" />
              <b style={{ fontSize: 12, color: Colors.secondary }}>
                My Account
              </b>
            </Box>
          </Link>
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleClose}>
          <MUILink underline="none" onClick={logout}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <ExitToAppIcon color="primary" />
              <b style={{ fontSize: 12, color: Colors.secondary }}>Logout</b>
            </Box>
          </MUILink>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
