import React from "react";
import { Avatar } from "@mui/material";

// const useStyles = makeStyles(theme => {
//   return {
//     avatar: {
//       width: theme.spacing(3),
//       height: theme.spacing(3),
//       border: "1px solid white"
//     }
//   };
// });

export default function SmallAvatar(props: any) {
  // const classes = useStyles();
  return (
    <Avatar
      sx={{ width: "30px", height: "30px", border: "1px solid white" }}
      // className={classes.avatar}
      {...props}
    />
  );
}
