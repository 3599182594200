import { Box, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalLoaderContext } from "../../context/global-loader";
import { AuthContext } from "../../context/auth";
import ApiUri from "../../api/api-uri";
import API from "../../api/api";
import BaseComponentLogin from "./baseComponentLogin";
import ReCAPTCHA from "react-google-recaptcha";
import { getCookie, setCookie } from "../../App";
import { SnackbarContext } from "../../context/snackbar";
import { TranslationsContext } from "../../context/translations";
import { InformationContext } from "../../context/information";

export const isValidEmail = (email) => {
  // Regular expression for basic email validation
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
};

const Login = ({ app, profile }) => {
  const history = useHistory();
  const {
    setUser,
    setUserImageUrl,
    setJwt,
    profileNameLogin,
    setProfileNameLogin,
    profileImageURLLogin,
    setProfileImageURLLogin,
    profileUrlName,
    setProfileUrlName,
    showSignup,
    setShowSignup,
  } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const { translations, setTranslations, setHasFetchedTranslations } =
    useContext(TranslationsContext);
  const { setInformation } = useContext(InformationContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [recaptchaApproved, setRecaptchaApproved] = useState(false);
  const [hasFetchedProfileInfo, setHasFetchedProfileInfo] = useState(false);
  const location = useLocation();

  const getAppAndProfileFromUrl = () => {
    const { pathname } = location;
    const parts = pathname.split("/").filter((part) => part);

    let appFromUrl, profileFromUrl;
    if (parts.length >= 2) {
      appFromUrl = parts[0];
      profileFromUrl = parts[1];
    }
    return { appFromUrl, profileFromUrl };
  };

  const buttonSX = {
    backgroundColor: "#3399FE",
    "&:hover": {
      backgroundColor: "#004c97",
    },
  };

  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName ? `${profileName} - Login` : "Login";

  useEffect(() => {
    if (profileNameLogin && profileImageURLLogin && profileUrlName) {
      setHasFetchedProfileInfo(true);
    } else {
      startGlobalLoader();
      const tokenFromLocalStorage = localStorage.getItem("activationToken");
      let payload: any = {
        domain: window.location.hostname.split(".").slice(1).join("."),
        application: app,
        profile: profile,
      };
      if (tokenFromLocalStorage) {
        payload = { ...payload, token: tokenFromLocalStorage };
      }
      API.post(ApiUri.PROFILE + ApiUri.INFORMATION, payload).then(
        (response) => {
          if (response.data.data?.invitation?.email) {
            localStorage.setItem(
              "emailForSignup",
              response.data.data?.invitation?.email
            );
          }
          setShowSignup(response.data.data.is_registration_enabled);
          setCookie(
            process.env.REACT_APP_WHITE_LABEL_COOKIE,
            response.data.data.white_label,
            365
          );
          setCookie("PROFILE_NAME_TITLE", response.data.data.profile_name, 365);
          setInformation(response.data.data);
          // todo: set these cookies names in env
          setCookie("APP_NAME", response.data.data.app_name, 365);
          setCookie("WEB", response.data.data.web, 365);
          const { profileFromUrl, appFromUrl } = getAppAndProfileFromUrl();
          if (profileFromUrl !== response.data.data.url_name) {
            const newPath = `/${appFromUrl ?? "mmdz"}/${
              response.data.data.url_name
            }/`;
            history.replace(newPath);
          }
          const domainUrl = response.data.data.domain;
          //todo: use value from env
          const domainUrlCookieName = "DOMAIN_URL";
          setCookie(domainUrlCookieName, domainUrl, 365);
          setProfileImageURLLogin(response?.data?.data?.logo_url ?? "");
          setProfileNameLogin(
            response.data.data.profile_name ??
              response.data.data.profile_information.name
          );
          setProfileUrlName(response.data.data.url_name);
          stopGlobalLoader();
          setHasFetchedProfileInfo(true);
        },
        (error) => {
          stopGlobalLoader();
          setHasFetchedProfileInfo(true);
        }
      );
    }
  }, []);

  const logIn = (event) => {
    event.preventDefault();
    startGlobalLoader();
    const appCookie = getCookie(process.env.REACT_APP_MMAZ_APPLICATION_COOKIE);
    const profileCookie = getCookie(process.env.REACT_APP_MMAZ_PROFILE_COOKIE);
    const loginJSON = {
      email: email,
      password: password,
      application: appCookie,
      profile: profileCookie,
    };
    API.post(ApiUri.AUTH + ApiUri.LOGIN, loginJSON).then(
      (response) => {
        const jwtToken = response.data.token;
        setCookie(process.env.REACT_APP_MCZTOKEN_COOKIE, jwtToken, 14);
        const config = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };
        API.get(ApiUri.MY_ACCOUNT, config).then(
          (response) => {
            const userFromEndpoint = response.data.data;
            API.get(ApiUri.PUBLIC + ApiUri.TRANSLATIONS, {
              params: {
                locale: userFromEndpoint?.user_profile?.language?.code ?? "en",
              },
            }).then(
              (response) => {
                setHasFetchedTranslations(true);
                setTranslations(response.data.data);
                API.get(ApiUri.USER + ApiUri.IMAGE, config).then(
                  (response) => {
                    const imageUrl = response.data.data.image;
                    setUserImageUrl(imageUrl);
                    setUser(userFromEndpoint);
                    setJwt(jwtToken);
                    const activationToken =
                      localStorage.getItem("activationToken");
                    if (activationToken) {
                      const formData = { token: activationToken };
                      API.post(
                        ApiUri.INVITATION + ApiUri.ACCEPT,
                        formData,
                        config
                      ).then(
                        (response) => {
                          localStorage.removeItem("activationToken");
                          localStorage.removeItem("emailForSignup");
                          stopGlobalLoader();
                          if (response?.data?.data?.redirect_url) {
                            window.location.replace(
                              response?.data?.data?.redirect_url
                            );
                          } else {
                            history.push("/dashboard");
                          }
                        },
                        (error) => {
                          if (
                            error?.response?.data?.code === "login_required" ||
                            error?.response?.data?.code === "signup_required"
                          ) {
                            localStorage.setItem(
                              "activationToken",
                              activationToken
                            );
                            const app =
                              error?.response?.data?.context?.application;
                            const profile =
                              error?.response?.data?.context?.profile;
                            if (
                              error?.response?.data?.code === "login_required"
                            ) {
                              initSnackbarGeneric(
                                error?.response?.data?.message
                              );
                              initSnackbarError(error);
                              stopGlobalLoader();
                              history.push("/dashboard");
                            } else if (
                              error?.response?.data?.code === "signup_required"
                            ) {
                              initSnackbarGeneric(
                                error?.response?.data?.message
                              );
                              stopGlobalLoader();
                              history.push("/dashboard");
                            }
                          } else if (
                            error?.response?.data?.code === "accepted" ||
                            error?.response?.data?.code === "invalid_invitation"
                          ) {
                            initSnackbarError(error?.response?.data?.message);
                            stopGlobalLoader();
                            history.push("/dashboard");
                          } else {
                            initSnackbarError(error?.response?.data?.message);
                            stopGlobalLoader();
                            history.push("/dashboard");
                          }
                        }
                      );
                    } else {
                      stopGlobalLoader();
                      history.push("/dashboard");
                    }
                  },
                  (error) => {
                    stopGlobalLoader();
                  }
                );
              },
              (error) => {
                stopGlobalLoader();
              }
            );
          },
          (error) => {
            stopGlobalLoader();
          }
        );
      },
      (error) => {
        initSnackbarError(error.response.data.message);
        stopGlobalLoader();
      }
    );
    // history.push("/");
  };

  function onChangeRecaptcha(value) {
    if (value) {
      setRecaptchaApproved(true);
    } else {
      setRecaptchaApproved(false);
    }
  }

  const handleBlur = () => {
    setEmailError(!isValidEmail(email));
  };

  const emailIsValid = isValidEmail(email);

  const handleChangeEmail = (event) => {
    const input = event.target.value.trim();
    setEmail(input);
    if (emailError) {
      setEmailError(!isValidEmail(input));
    }
  };

  return (
    <BaseComponentLogin>
      {hasFetchedProfileInfo && (
        <>
          <Stack alignItems="center" width={"100%"}>
            <img
              src={profileImageURLLogin}
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            />
            <Typography align="center" sx={{ wordBreak: "break-word" }}>
              <h1>{profileNameLogin}</h1>
            </Typography>
          </Stack>
          <Stack
            spacing={1.5}
            // marginTop={1.5}
            alignItems="center"
            width={"100%"}
          >
            <TextField
              fullWidth
              size="small"
              label={translations["label.email"] ?? "Email"}
              value={email}
              onChange={handleChangeEmail}
              sx={{ paddingBottom: 2 }}
              onBlur={handleBlur}
              error={emailError}
              // helperText={emailError ? "Invalid email" : ""}
              inputProps={{ autoCapitalize: "none" }}
            />
            <TextField
              fullWidth
              size="small"
              type="password"
              label={translations["label.password"] ?? "Password"}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              sx={{ paddingBottom: 1 }}
            />
            <Box
              alignSelf="flex-end"
              // marginTop={0.5}
            >
              <Link
                to={`/${app}/${profile}/forgotten-password`}
                style={{ color: "#3399FE" }}
              >
                {translations["button.forgot_password"] ?? "Forgot password?"}
              </Link>
            </Box>
          </Stack>

          <Stack
            alignItems="center"
            width={"100%"}
            // marginTop={3}
            spacing={2}
            // paddingBottom={2}
          >
            <Box paddingTop={1}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITEKEY_RECAPTCHA}
                onChange={onChangeRecaptcha}
              />
            </Box>

            <ActionButtonSmall
              sx={buttonSX}
              onClick={logIn}
              disabled={
                email.length === 0 ||
                password.length === 0 ||
                !emailIsValid ||
                !recaptchaApproved
              }
              fullWidth
              ignoreStyles={true}
            >
              {translations["button.log_in"] ?? "Log In"}
            </ActionButtonSmall>
          </Stack>

          <Stack
            spacing={2}
            alignSelf={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            // mt={"auto"}
            width={"100%"}
          >
            <Stack
              paddingTop={1}
              spacing={0.5}
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
            >
              <Typography fontSize={15} lineHeight={0.5}>
                {translations["label.no_activation_email"] ??
                  "Haven't received the activation email?"}{" "}
              </Typography>
              <Typography fontSize={15}>
                <Link
                  to={`/${app}/${profile}/activation/resend`}
                  style={{ color: "#3399FE" }}
                >
                  {translations["button.resend_activation_email"] ??
                    "Resend Activation Email"}
                </Link>
              </Typography>
            </Stack>
            {showSignup && (
              <Typography fontSize={15}>
                {translations["help.no_account"] ??
                  "Don't have an account yet?"}{" "}
                <Link
                  to={`/${app}/signup/${profile}`}
                  style={{ color: "#3399FE" }}
                >
                  {translations["button.sign_up"] ?? "Sign up"}
                </Link>
              </Typography>
            )}
          </Stack>
        </>
      )}
    </BaseComponentLogin>
  );
};

export default Login;
