import { Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import AppIcon from "../../components/app-icon";
import NewsletterAndMarketingOptInModal from "./NewsletterAndMarketingOptInModal";
import { AuthContext } from "../../context/auth";
import { TranslationsContext } from "../../context/translations";
import { getCookie } from "../../App";

export default function Dashboard() {
  const [
    openNewsletterAndMarketingOptInModal,
    setOpenNewsletterAndMarketingOptInModal,
  ] = useState(true);

  const whiteLabel = getCookie(process.env.REACT_APP_WHITE_LABEL_COOKIE);

  //todo: use value from env
  const domainUrl = getCookie("DOMAIN_URL");
  const profileCookie = getCookie(process.env.REACT_APP_MCZ_PROFILE_COOKIE);
  const profileTypeCookie = getCookie(
    process.env.REACT_APP_MCZ_PROFILE_TYPE_COOKIE
  );

  const { user } = useContext(AuthContext);

  const { translations } = useContext(TranslationsContext);

  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName ? `${profileName} - Dashboard` : "Dashboard";

  const showMMDZ = (user as any).user_applications.some(
    (app) => app?.application === "mmdz"
  );

  const showMMPZ = (user as any)?.user_applications.some(
    (app) => app?.application === "mmpz"
  );

  return (
    <Stack
      direction="row"
      // direction={{ xs: "column", sm: "row" }}
      // spacing="80px"
      justifyContent="space-around"
      // justifyContent="center"
      alignItems="center"
      // alignItems="center"
      width="100%"
      minHeight="100%"
      paddingTop={{ xs: 0, md: "20vh" }}
      flexWrap="wrap"
    >
      {!whiteLabel ? (
        <>
          <AppIcon
            imageSource="/MMAZ-logo.png"
            appName={translations["title.mmaz"] ?? "My Music Account Zone"}
            linkUrl="/my-account"
          />
          {showMMDZ && (
            <AppIcon
              imageSource="/MMDZ-logo.png"
              appName={
                translations["title.mmdz"] ?? "My Music Distribution Zone"
              }
              linkUrl={`https://mmdz.${domainUrl}`}
            />
          )}
          {showMMPZ && (
            <AppIcon
              imageSource="/MMPZ-logo.png"
              appName={translations["title.mmpz"] ?? "My Music Publishing Zone"}
              linkUrl={
                process.env.REACT_APP_MMPZ_URL +
                `/dashboard?parent=${profileCookie ?? ""}&profile_type=${
                  profileTypeCookie ?? ""
                }`
              }
            />
          )}
        </>
      ) : (
        <>
          <AppIcon
            imageSource="/MMAZ-logo.png"
            appName={translations["title.mmaz"] ?? "My Music Account Zone"}
            linkUrl="/my-account"
          />
          {showMMDZ && (
            <AppIcon
              imageSource="/MMDZ-logo.png"
              appName={
                translations["title.mmdz"] ?? "My Music Distribution Zone"
              }
              linkUrl={`https://mmdz.${domainUrl}`}
            />
          )}
          {/* {showMMPZ && (
            <AppIcon
              imageSource="/MMPZ-logo.png"
              appName={translations["title.mmpz"] ?? "My Music Publishing Zone"}
              linkUrl={process.env.REACT_APP_MMPZ_URL + "/"}
            />
          )} */}
        </>
      )}
      {/* todo: bring this back when we reimplement this modal */}
      {/* {user?.user_role !== 1 && (
        <NewsletterAndMarketingOptInModal
          modalOpened={openNewsletterAndMarketingOptInModal}
          handleClose={() => setOpenNewsletterAndMarketingOptInModal(false)}
        />
      )} */}
    </Stack>
  );
}
