import { createTheme } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import Colors from "../enums/colors";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    menuText: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  menuText: React.CSSProperties;
}

const theme = createTheme({
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
    fontSize: 14,
  } as ExtendedTypographyOptions,
  palette: {
    primary: {
      main: Colors.primary,
      dark: Colors.accent,
      light: Colors.success,
    },
    secondary: {
      main: Colors.secondary,
    },
  },
} as ThemeOptions);

export default theme;
