import React, { useState, createContext, useContext } from "react";
import HomeIcon from "@mui/icons-material/Home";
import {
  Block,
  Group,
  Handyman,
  Help,
  Person,
  EventNote,
  Logout,
} from "@mui/icons-material";
import { AuthContext } from "../context/auth";
import { useLocation } from "react-router-dom";

const ADMIN_NAVIGATION = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon />,
    open: false,
    children: [],
  },
  {
    path: "/my-account",
    name: "Account",
    icon: <Person />,
    open: false,
    children: [],
  },
  {
    path: "/users",
    name: "Users",
    icon: <Group />,
    open: false,
    children: [],
  },
  {
    path: "/blocked-ips",
    name: "Blocked IP's",
    icon: <Block />,
    open: false,
    children: [],
  },
  {
    path: "/maintenance-mode",
    name: "Maintenance Mode",
    icon: <Handyman />,
    open: false,
    children: [],
  },
  {
    path: process.env.REACT_APP_KIBANA_URL + "/login?next=%2F",
    external: true,
    name: "Event Logger",
    icon: <EventNote />,
    open: false,
    children: [],
  },
  {
    path: "/logout",
    name: "Logout",
    icon: <Logout />,
    open: false,
    children: [],
    function: "logout",
  },
];

const NORMAL_NAVIGATION = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon />,
    open: false,
    children: [],
  },
  {
    path: "/my-account",
    name: "Account",
    icon: <Person />,
    open: false,
    children: [],
  },
  {
    path: "/help-zone",
    name: "Help Zone",
    icon: <Help />,
    open: false,
    children: [],
  },
  {
    path: "/logout",
    name: "Logout",
    icon: <Logout />,
    open: false,
    children: [],
    function: "logout",
  },
];

export interface INavigationContext {
  navigation: any;
  setNavigation: any;
}

export const NavigationContext = createContext({} as INavigationContext);

const NavigationContextProvider = ({ children }: any) => {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const navigationType =
    user?.user_role === 1 ? ADMIN_NAVIGATION : NORMAL_NAVIGATION;

  const appNavigation = navigationType?.map((navigationPage: any) => {
    if (navigationPage.path === location.pathname) {
      navigationPage.open = true;
    }

    // const children = navigationPage.children.map((child: any) => {
    //   if (child.path === location.pathname) {
    //     navigationPage.open = true;
    //     child.active = true;
    //   }
    //   return child;
    // });

    // navigationPage.children = children;
    return navigationPage;
  });

  const [navigation, setNavigation] = useState(appNavigation);

  return (
    <NavigationContext.Provider
      value={{
        navigation,
        setNavigation,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
