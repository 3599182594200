import { Box, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import { AuthContext } from "../../context/auth";
import Colors from "../../enums/colors";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { GlobalLoaderContext } from "../../context/global-loader";
import ApiUri from "../../api/api-uri";
import API from "../../api/api";
import BackButton from "../../components/BackButton";
import { getCookie } from "../../App";
import { TranslationsContext } from "../../context/translations";
import { InformationContext } from "../../context/information";
// import getUserLocale from "get-user-locale";

const BaseComponentLogin = ({ children, backButton = false }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const {
    setTranslations,
    translations,
    hasFetchedTranslations,
    setHasFetchedTranslations,
  } = useContext(TranslationsContext);

  const { information } = useContext(InformationContext);

  useEffect(() => {
    if (!hasFetchedTranslations) {
      const locale = navigator.language;
      const language = locale.split("-")[0];
      API.get(ApiUri.PUBLIC + ApiUri.TRANSLATIONS, {
        params: {
          locale: language,
        },
      }).then(
        (response) => {
          setHasFetchedTranslations(true);
          setTranslations(response.data.data);
        },
        (error) => {}
      );
    }
  }, []);

  return (
    <Stack
      alignItems="center"
      justifyContent={"center"}
      sx={{
        minHeight: "100vh",
        width: "100vw",
        background:
          "linear-gradient(90deg, rgba(44,65,88,1) 0%, rgba(56,90,119,1) 100%)",
      }}
      spacing={1}
    >
      <Paper
        sx={{
          width: {
            xs: "100%",
            sm: "50%",
            md: "400px",
            lg: "400px",
            xl: "400px",
          },
          minHeight: "75vh",
          backgroundColor: Colors.white,
          marginY: "16px",
        }}
      >
        <Stack
          minHeight="75vh"
          padding={4}
          position={"relative"}
          // sx={{ backgroundColor: "yellow" }}
        >
          {backButton && (
            <Box position="absolute" paddingTop={2} top={2}>
              <BackButton />
            </Box>
          )}
          <Stack
            alignItems="center"
            justifyContent={"space-between"}
            marginTop={backButton ? "40px" : 0}
            // sx={{ backgroundColor: "green" }}
            minHeight={"75vh"}
          >
            {children}
          </Stack>
        </Stack>
      </Paper>
      <Stack
        paddingBottom={1}
        sx={{
          width: {
            xs: "90%",
            sm: "50%",
            md: "450px",
            lg: "450px",
            xl: "450px",
          },
        }}
        alignItems={"center"}
      >
        {information &&
          (information?.white_label ? (
            <Typography fontSize={15} color="white">
              <a
                style={{ color: "#3399FE" }}
                href={information?.web}
                target="_blank"
              >
                {information?.app_name}
              </a>
            </Typography>
          ) : (
            <Typography fontSize={15} color="white">
              <a
                style={{ color: "#3399FE" }}
                href="https://www.myclientzone.com/"
                target="_blank"
              >
                MyClientZone v5.0
              </a>{" "}
              - Copyright © {currentYear} Nick Dunn Enterprises Ltd.
            </Typography>
          ))}
      </Stack>
    </Stack>
  );
};

export default BaseComponentLogin;
