import {
  Fab,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import Colors from "../../enums/colors";
import { useHistory } from "react-router-dom";
import { SnackbarContext } from "../../context/snackbar";
import { GlobalLoaderContext } from "../../context/global-loader";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";
import BaseComponentLogin from "./baseComponentLogin";
import { useParams } from "react-router";
import { TranslationsContext } from "../../context/translations";
import { getPasswordValidationTexts } from "../account/PasswordSettings";
import { AuthContext } from "../../context/auth";
import { getCookie } from "../../App";

const ChangePassword = () => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const [passwordIsBeingEdited, setPasswordIsBeingEdited] =
    React.useState(false);
  const [passwordHasAtLeast11Characters, setPasswordHasAtLeast11Characters] =
    React.useState(false);
  const [passwordsMatch, setPasswordsMatch] = React.useState(false);
  const [passwordHasAtLeastOneDigit, setPasswordHasAtLeastOneDigit] =
    React.useState(false);
  const [
    passwordHasAtLeastOneUppercaseLetter,
    setPasswordHasAtLeastOneUppercaseLetter,
  ] = React.useState(false);
  const [
    passwordHasAtLeastOneLowercaseLetter,
    setPasswordHasAtLeastOneLowercaseLetter,
  ] = React.useState(false);
  const [
    passwordHasAtLeastOneSpecialCharacter,
    setPasswordHasAtLeastOneSpecialCharacter,
  ] = React.useState(false);

  const { translations } = useContext(TranslationsContext);

  const { profileNameLogin } = useContext(AuthContext);

  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName
    ? `${profileName} - Change Password`
    : "Change Password";

  const buttonSX = {
    backgroundColor: "#3399FE",
    "&:hover": {
      backgroundColor: "#004c97",
    },
  };

  const { profile } = useParams();

  const handleClick = async (event) => {
    startGlobalLoader();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const application = urlParams.get("application");
    const payload = {
      token: token,
      password: password,
      repeat_password: repeatPassword,
    };
    API.post(ApiUri.AUTH + ApiUri.PASSWORD + ApiUri.RESET, payload).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess(response.data.data);
        history.push(`/${application}/${profile}`);
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  const handleEditPassword = (event) => {
    let { name, value } = event.target;
    value = value.trim();
    if (!passwordIsBeingEdited) {
      setPasswordIsBeingEdited(true);
    }
    if (name === "password") {
      setPasswordsMatch(value === repeatPassword);
      setPasswordHasAtLeast11Characters(value.length >= 11);
      setPasswordHasAtLeastOneDigit(/\d/.test(value));
      setPasswordHasAtLeastOneUppercaseLetter(/[A-Z]/.test(value));
      setPasswordHasAtLeastOneLowercaseLetter(/[a-z]/.test(value));
      setPasswordHasAtLeastOneSpecialCharacter(
        /[!@#$%^&*(),.?":{}|<>]/.test(value)
      );
      setPassword(value);
    } else if (name === "repeatPassword") {
      setPasswordsMatch(value === password);
      setRepeatPassword(value);
    }
  };

  const passwordsAreInvalid = passwordIsBeingEdited
    ? !passwordHasAtLeast11Characters ||
      !passwordHasAtLeastOneDigit ||
      !passwordsMatch ||
      !passwordHasAtLeastOneLowercaseLetter ||
      !passwordHasAtLeastOneUppercaseLetter ||
      !passwordHasAtLeastOneSpecialCharacter
    : true;

  const {
    minimumPasswordLengthText,
    passwordsMatchingText,
    atLeastOneDigitText,
    atLeastOneUppercaseText,
    atLeastOneLowercaseText,
    atLeastOneSpecialText,
  } = getPasswordValidationTexts(translations);

  return (
    <BaseComponentLogin>
      <Stack alignItems="center" width={"100%"} spacing={2}>
        <h2>{translations["heading.change_password"] ?? "Change Password"}</h2>
        <TextField
          fullWidth
          size="small"
          label={translations["label.new_password"] ?? "New Password"}
          name="password"
          value={password}
          onChange={handleEditPassword}
          type="password"
        />
        <TextField
          fullWidth
          size="small"
          label={
            translations["label.repeat_new_password"] ?? "Repeat New Password"
          }
          name="repeatPassword"
          value={repeatPassword}
          onChange={handleEditPassword}
          type="password"
        />

        <ActionButtonSmall
          sx={buttonSX}
          onClick={handleClick}
          disabled={passwordsAreInvalid}
          fullWidth
        >
          {translations["button.change"] ?? "Change"}
        </ActionButtonSmall>

        <Stack spacing={0.5}>
          <Typography
            variant="subtitle2"
            color={
              passwordIsBeingEdited
                ? passwordHasAtLeast11Characters
                  ? "#66bb6a"
                  : Colors.error
                : Colors.grey
            }
          >
            {minimumPasswordLengthText}
          </Typography>

          <Typography
            variant="subtitle2"
            color={
              passwordIsBeingEdited
                ? passwordHasAtLeastOneDigit
                  ? "#66bb6a"
                  : Colors.error
                : Colors.grey
            }
          >
            {atLeastOneDigitText}
          </Typography>

          <Typography
            variant="subtitle2"
            color={
              passwordIsBeingEdited
                ? passwordHasAtLeastOneUppercaseLetter
                  ? "#66bb6a"
                  : Colors.error
                : Colors.grey
            }
          >
            {atLeastOneUppercaseText}
          </Typography>

          <Typography
            variant="subtitle2"
            color={
              passwordIsBeingEdited
                ? passwordHasAtLeastOneLowercaseLetter
                  ? "#66bb6a"
                  : Colors.error
                : Colors.grey
            }
          >
            {atLeastOneLowercaseText}
          </Typography>

          <Typography
            variant="subtitle2"
            color={
              passwordIsBeingEdited
                ? passwordHasAtLeastOneSpecialCharacter
                  ? "#66bb6a"
                  : Colors.error
                : Colors.grey
            }
          >
            {atLeastOneSpecialText}
          </Typography>

          <Typography
            variant="subtitle2"
            color={
              passwordIsBeingEdited
                ? passwordsMatch
                  ? "#66bb6a"
                  : Colors.error
                : Colors.grey
            }
          >
            {passwordsMatchingText}
          </Typography>
        </Stack>
      </Stack>
    </BaseComponentLogin>
  );
};

export default ChangePassword;
