import React, { useContext, useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { GlobalLoaderContext } from "../../context/global-loader";
import { SnackbarContext } from "../../context/snackbar";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";

import { Grid } from "@mui/material";
import { AuthContext } from "../../context/auth";
import { LanguagesContext } from "../../context/languages";
import { TimezonesContext } from "../../context/timezones";
import { CountriesContext } from "../../context/countries";
import { CurrenciesContext } from "../../context/currencies";
import { ReportEncodingsContext } from "../../context/report-encodings";
import PersonalSettings from "./PersonalSettings";
import PasswordSettings from "./PasswordSettings";
import PreferencesSettings from "./PreferencesSettings";
import TwoStepVerification from "../users/user/TwoStepVerification";
import { TranslationsContext } from "../../context/translations";
import { Crop } from "../../components/image-crop/types";
import { ReactCrop } from "../../components/image-crop/ReactCrop";
import ImageCropModal from "./ImageCropModal";
import { getCookie } from "../../App";

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Account() {
  // const classes = useStyles();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { user, setUser, userImageUrl, setUserImageUrl, jwt } =
    useContext(AuthContext);
  const { languages, setLanguages } = useContext(LanguagesContext);
  const { timezones, setTimezones } = useContext(TimezonesContext);
  const { countries, setCountries } = useContext(CountriesContext);
  const { currencies, setCurrencies } = useContext(CurrenciesContext);
  const { reportEncodings, setReportEncodings } = useContext(
    ReportEncodingsContext
  );
  const [account, setAccount] = useState(user as any);
  const [imageToBeCropped, setImageToBeCropped] = useState("");
  const [openCropTool, setOpenCropTool] = useState(false);
  const [languageHasChanged, setLanguageHasChanged] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName ? `${profileName} - Account` : "Account";

  useEffect(() => {
    const fetchLanguages = async () => {
      const response = await API.get(ApiUri.DATA + ApiUri.LANGUAGE, config);
      setLanguages(response.data.data);
    };
    const fetchCountries = async () => {
      const response = await API.get(ApiUri.DATA + ApiUri.COUNTRY, config);
      setCountries(response.data.data);
    };
    const fetchTimezones = async () => {
      const response = await API.get(ApiUri.DATA + ApiUri.TIMEZONE, config);
      setTimezones(response.data.data);
    };
    const fetchCurrencies = async () => {
      const response = await API.get(ApiUri.DATA + ApiUri.CURRENCY, config);
      setCurrencies(response.data.data);
    };
    const fetchReportEncodings = async () => {
      const response = await API.get(
        ApiUri.DATA + ApiUri.REPORT_ENCODINGS,
        config
      );
      setReportEncodings(response.data.data);
    };

    const checkData = async () => {
      startGlobalLoader();
      if (currencies.length === 0) {
        fetchCurrencies();
      }
      if (reportEncodings.length === 0) {
        fetchReportEncodings();
      }
      if (languages.length === 0) {
        fetchLanguages();
      }
      if (countries.length === 0) {
        fetchCountries();
      }
      if (timezones.length === 0) {
        await fetchTimezones();
      }
      stopGlobalLoader();
    };
    checkData();
  }, []);

  const [avatarUrl, setAvatarUrl] = React.useState(userImageUrl);
  const [avatar, setAvatar] = React.useState<any>();

  // Top form validaiton

  const [emailIsValid, setEmailIsValid] = React.useState(true);
  const [firstNameIsValid, setFirstNameIsValid] = React.useState(true);
  const [lastNameIsValid, setLastNameIsValid] = React.useState(true);

  // Password validation

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  const [passwordIsBeingEdited, setPasswordIsBeingEdited] =
    React.useState(false);
  const [
    newPasswordHasAtLeast11Characters,
    setNewPasswordHasAtLeast11Characters,
  ] = React.useState(false);
  const [passwordsMatch, setPasswordsMatch] = React.useState(false);
  const [newPasswordHasAtLeastOneDigit, setNewPasswordHasAtLeastOneDigit] =
    React.useState(false);
  const [
    newPasswordHasAtLeastOneUppercaseLetter,
    setNewPasswordHasAtLeastOneUppercaseLetter,
  ] = React.useState(false);
  const [
    newPasswordHasAtLeastOneLowercaseLetter,
    setNewPasswordHasAtLeastOneLowercaseLetter,
  ] = React.useState(false);
  const [
    newPasswordHasAtLeastOneSpecialCharacter,
    setNewPasswordHasAtLeastOneSpecialCharacter,
  ] = React.useState(false);

  // Entire form validation

  const [formIsValid, setFormIsValid] = React.useState(true);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);

  const { translations, setTranslations } = useContext(TranslationsContext);

  useEffect(() => {
    setFormIsValid(emailIsValid && firstNameIsValid && lastNameIsValid);
  }, [emailIsValid, firstNameIsValid, lastNameIsValid]);

  const handleEdit = (event) => {
    const { name, value } = event.target;
    const newAccount = account;
    if (name === "email") {
      const validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      setEmailIsValid(value.match(validEmailRegex));
      newAccount.email = value;
    } else if (name === "firstName") {
      setFirstNameIsValid(value.trim().length > 0);
      newAccount.user_profile.first_name = value;
    } else if (name === "lastName") {
      setLastNameIsValid(value.trim().length > 0);
      newAccount.user_profile.last_name = value;
    } else if (name === "country") {
      const selectedCountry = countries?.find(
        (country) => country.id === value
      );
      newAccount.user_profile.country = selectedCountry;
    } else if (name === "timezone") {
      const selectedTimezone = timezones.find(
        (timezone) => timezone.id === value
      );
      newAccount.user_profile.timezone = selectedTimezone;
    } else if (name === "mailing-list") {
      const { checked } = event.target;
      newAccount.user_profile.signed_up_to_mailing_list = checked;
    } else if (name === "language") {
      const selectedLanguage = languages?.find(
        (language) => language.id === value
      );
      newAccount.user_profile.language = selectedLanguage;
      setLanguageHasChanged(true);
    } else if (name === "currency") {
      const selectedCurrency = currencies?.find(
        (currency) => currency.id === value
      );
      newAccount.product_currency = selectedCurrency;
    } else if (name === "encoding") {
      const selectedEncoding = reportEncodings?.find(
        (encoding) => encoding.id === value
      );
      newAccount.report_encoding = selectedEncoding.id;
    } else {
      newAccount[name] = value;
    }

    setAccount((previousState) => ({ ...previousState, ...newAccount }));
  };

  const handleAvatarChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileUrl = URL.createObjectURL(file);
      setImageToBeCropped(fileUrl);
      setOpenCropTool(true);

      // setAvatar(file);
      // setAvatarUrl(fileUrl);
    }
  };

  const updateAvatarLocally = async (blob, url) => {
    // setUserImageUrl(url);
    setAvatarUrl(url);
    setAvatar(blob);
    handleCloseCropModal();

    // if (!avatar) return;
    // if (!blob) return;

    // const reader = new FileReader();
    // reader.onload = async (e) => {
    //   const arrayBuffer = e.target?.result;
    //   if (!arrayBuffer) return;

    //   // const blob = new Blob([arrayBuffer], { type: avatar.type });

    //   const testConfig = {
    //     headers: {
    //       Authorization: `Bearer ${jwt}`,
    //       "Content-Type": "application/octet-stream",
    //     },
    //   };

    //   startGlobalLoader();
    //   handleCloseCropModal();
    //   API.put(
    //     `${ApiUri.USER}${ApiUri.IMAGE}${ApiUri.UPLOAD}`,
    //     blob,
    //     testConfig
    //   ).then(
    //     (response) => {
    //       setUserImageUrl(url);
    //       // initSnackbarSuccess(
    //       //   translations["flash_message.personal_settings_updated"] ??
    //       //     "Personal settings updated successfully."
    //       // );
    //       stopGlobalLoader();
    //     },
    //     (error) => {
    //       console.log("🚀 ~ ).then ~ error:", error);
    //       // handleCloseCropModal();
    //       stopGlobalLoader();
    //     }
    //   );
    // };

    // reader.readAsArrayBuffer(blob);
  };

  const uploadAvatar = async () => {
    // if (!avatar) return;

    if (!avatar) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const arrayBuffer = e.target?.result;
      if (!arrayBuffer) return;

      // const blob = new Blob([arrayBuffer], { type: avatar.type });

      const testConfig = {
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/octet-stream",
        },
      };

      // startGlobalLoader();
      // handleCloseCropModal();
      await API.put(
        `${ApiUri.USER}${ApiUri.IMAGE}${ApiUri.UPLOAD}`,
        avatar,
        testConfig
      ).then(
        (response) => {
          setUserImageUrl(avatarUrl);
          // setUserImageUrl(url);
          // initSnackbarSuccess(
          //   translations["flash_message.personal_settings_updated"] ??
          //     "Personal settings updated successfully."
          // );
          // stopGlobalLoader();
        },
        (error) => {
          console.log("🚀 ~ ).then ~ error:", error);
          // handleCloseCropModal();
          // stopGlobalLoader();
        }
      );
    };

    reader.readAsArrayBuffer(avatar);
  };

  const handleSaveProfile = async (successMessage) => {
    startGlobalLoader();

    if (avatar) {
      await uploadAvatar();
    }

    const payload = {
      first_name: account?.user_profile?.first_name,
      last_name: account?.user_profile?.last_name,
      country: account?.user_profile?.country?.id,
      timezone: account?.user_profile?.timezone?.id,
      language: account?.user_profile?.language?.id,
      currency: account?.product_currency?.id,
      report_encoding: account?.report_encoding,
      signed_up_to_mailing_list:
        account?.user_profile?.signed_up_to_mailing_list,
    };

    await API.put(ApiUri.USER + ApiUri.UPDATE, payload, config).then(
      (response) => {
        const newUser = response.data.data;
        setUser(newUser);
        if (languageHasChanged) {
          API.get(ApiUri.PUBLIC + ApiUri.TRANSLATIONS, {
            params: {
              locale: newUser?.user_profile?.language?.code ?? "en",
            },
          }).then(
            (response) => {
              setTranslations(response.data.data);
              if (successMessage) {
                initSnackbarSuccess(successMessage);
              }
              setLanguageHasChanged(false);
              stopGlobalLoader();
            },
            (error) => {
              stopGlobalLoader();
            }
          );
        } else {
          if (successMessage) {
            initSnackbarSuccess(successMessage);
          }
          stopGlobalLoader();
        }
      },
      (error) => {
        initSnackbarError(error?.response?.data?.message);
        stopGlobalLoader();
      }
    );
  };

  const handleSavePassword = () => {
    startGlobalLoader();
    const payload = {
      current_password: currentPassword,
      new_password: newPassword,
      new_password_repeat: repeatNewPassword,
    };
    API.post(ApiUri.USER + ApiUri.CHANGE_PASSWORD, payload, config).then(
      (response) => {
        initSnackbarSuccess(
          translations["flash_message.password_updated"] ??
            "Password updated successfully."
        );
        setPasswordIsBeingEdited(false);
        setCurrentPassword("");
        setNewPassword("");
        setRepeatNewPassword("");
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error.response.data.message);
        stopGlobalLoader();
      }
    );
  };

  const handleEditPassword = (event) => {
    let { name, value } = event.target;
    value = value.trim();
    if (!passwordIsBeingEdited) {
      setPasswordIsBeingEdited(true);
    }
    if (name === "currentPassword") {
      setCurrentPassword(value);
    } else if (name === "newPassword") {
      setPasswordsMatch(value === repeatNewPassword);
      setNewPasswordHasAtLeast11Characters(value.length >= 11);
      setNewPasswordHasAtLeastOneDigit(/\d/.test(value));
      setNewPasswordHasAtLeastOneUppercaseLetter(/[A-Z]/.test(value));
      setNewPasswordHasAtLeastOneLowercaseLetter(/[a-z]/.test(value));
      setNewPasswordHasAtLeastOneSpecialCharacter(
        /[!@#$%^&*(),.?":{}|<>]/.test(value)
      );
      setNewPassword(value);
    } else if (name === "repeatNewPassword") {
      setPasswordsMatch(value === newPassword);
      setRepeatNewPassword(value);
    }
  };

  const handleCloseCropModal = () => {
    setOpenCropTool(false);
    setImageToBeCropped("");
  };

  const handleNewImage = (imageBlob, url) => {
    if (imageBlob) {
      updateAvatarLocally(imageBlob, url);
    }
  };

  return (
    <React.Fragment>
      {timezones.length > 0 && (
        <Grid container spacing={3}>
          <PersonalSettings
            account={account}
            handleAvatarChange={handleAvatarChange}
            avatarUrl={avatarUrl}
            formIsValid={formIsValid}
            handleSaveProfile={handleSaveProfile}
            handleEdit={handleEdit}
            emailIsValid={emailIsValid}
            firstNameIsValid={firstNameIsValid}
            lastNameIsValid={lastNameIsValid}
          />

          <PasswordSettings
            currentPassword={currentPassword}
            handleEditPassword={handleEditPassword}
            newPassword={newPassword}
            repeatNewPassword={repeatNewPassword}
            passwordIsBeingEdited={passwordIsBeingEdited}
            newPasswordHasAtLeast11Characters={
              newPasswordHasAtLeast11Characters
            }
            passwordsMatch={passwordsMatch}
            newPasswordHasAtLeastOneDigit={newPasswordHasAtLeastOneDigit}
            newPasswordHasAtLeastOneUppercaseLetter={
              newPasswordHasAtLeastOneUppercaseLetter
            }
            newPasswordHasAtLeastOneLowercaseLetter={
              newPasswordHasAtLeastOneLowercaseLetter
            }
            newPasswordHasAtLeastOneSpecialCharacter={
              newPasswordHasAtLeastOneSpecialCharacter
            }
            handleSavePassword={handleSavePassword}
          />

          <PreferencesSettings
            account={account}
            handleEdit={handleEdit}
            formIsValid={formIsValid}
            handleSaveProfile={handleSaveProfile}
          />

          <TwoStepVerification config={config} />
          {openCropTool && (
            <ImageCropModal
              modalOpened={openCropTool}
              handleCloseModal={handleCloseCropModal}
              imageToBeCropped={imageToBeCropped}
              handleConfirm={handleNewImage}
            />
          )}
        </Grid>
      )}
    </React.Fragment>
  );
}
