import React, { createContext, SyntheticEvent, useState } from "react";
import { Slide, Snackbar } from "@mui/material";
import Colors from "../enums/colors";

export interface ISnackbarContext {
  initSnackbarSuccess: Function;
  initSnackbarError: Function;
  initSnackbarGeneric: Function;
}

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export const SnackbarContext = createContext({} as ISnackbarContext);

const SnackbarContextProvider = ({ children }: any) => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState("");

  const handleSnackbarClose = (event: SyntheticEvent, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const initSnackbarSuccess = (message: string) => {
    setSnackbarColor(Colors.accent);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const initSnackbarGeneric = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const initSnackbarError = (error: any) => {
    let message: null | string = null;

    if (typeof error === "string") {
      message = error;
    } else {
      if (error.response.status === 400) {
        message = error.response.data.message;
      }
      if (error.response.status === 403) {
        message = error.response.data.message;
      }
      if (error.response.status === 409) {
        message = error.response.data.message;
      }
    }

    setSnackbarColor(Colors.secondary);
    setSnackbarMessage(message ? message : "Unknown error occurred");
    setSnackbarOpen(true);
  };

  return (
    <SnackbarContext.Provider
      value={{
        initSnackbarSuccess,
        initSnackbarError,
        initSnackbarGeneric,
      }}
    >
      <React.Fragment>
        {children}
        <Snackbar
          open={snackbarOpen}
          message={snackbarMessage}
          onClose={(event, reason) =>
            handleSnackbarClose(event as SyntheticEvent, reason)
          }
          autoHideDuration={8000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          TransitionComponent={SlideTransition}
          transitionDuration={{
            enter: 800,
            exit: 500,
          }}
          ContentProps={{
            style: {
              backgroundColor: snackbarColor,
              justifyContent: "center",
              fontWeight: 800,
            },
          }}
        />
      </React.Fragment>
    </SnackbarContext.Provider>
  );
};

export default SnackbarContextProvider;
