import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

export default function PageTransition(Page: any) {

  return (props: any) => (
    <div className="page">
      <ReactCSSTransitionGroup
        transitionAppear={true}
        transitionAppearTimeout={300}
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
        transitionName={"Grow"}
      >
        <Page {...props} />
      </ReactCSSTransitionGroup>
    </div>
  );
}
